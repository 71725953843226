export function useParser() {
  function blobToBase64(blob) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  return { blobToBase64 }
}

export async function readFile(file) {
  return new Promise((resolve) => {
    let reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = (evt) => {
      let image = evt.target.result

      resolve(image)
    }
  })
}
