import Vue from "vue"
import VueRouter from "vue-router"

import { getInitialConfig } from "./guards/initial"
import { isAllowedEntryRoute } from "./guards/isAllowedEntryRoute"
import { isHashEntry } from "./guards/isHashEntry"
import { setFlowStep } from "./guards/setFlowStep"
import { verifyRedirectCheckout } from "./guards/verifyRedirectCheckout"
import routes from "./routes"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(isAllowedEntryRoute)
router.beforeEach(isHashEntry)

router.beforeEach(getInitialConfig)
router.beforeEach(verifyRedirectCheckout)
router.beforeEach(setFlowStep)

export default router
