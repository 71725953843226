import http from "@/http"
import { changeChunkDeep } from "@/utils/vuex"

const LAST_GUEST_KEY = "zooxpass:lastguest"

/**
 *
 */
const state = {
  nps: {
    rating: "",
    comment: "",
  },
}

/**
 *
 */
const mutations = {
  changeNps: changeChunkDeep("nps"),
}

/**
 *
 */
const actions = {
  /**
   * Envia a avaliação NPS para o server
   */
  async evaluateProduct({ state, rootState }, guest = {}) {
    let guestId = guest.id

    if (!guestId) {
      const storageLast = localStorage.getItem(
        `${LAST_GUEST_KEY}:${rootState.reservation.reservation.reservationNumber}`
      )

      if (storageLast) {
        const guest = JSON.parse(storageLast)
        guestId = guest.guestId
      }
    }

    await http.post("/rating", {
      guestId: guestId,
      rating: state.nps.rating,
      comment: state.nps.comment,
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
