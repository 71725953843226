import { getCurrentInstance } from "vue"
import { useNamespacedState } from "vuex-composition-helpers"

/**
 * @param {*} root - root do componente
 */
export function useTracking() {
  const instance = getCurrentInstance()
  const { isQualified } = useNamespacedState("guest/auth", ["isQualified"])
  const { name } = useNamespacedState("company", ["name"])

  if (!instance) {
    throw new Error(`useZoox deve ser instanciada dentro da função setup().`)
  }

  const gtag = instance.proxy.$gtag

  const trackEvent = (eventName, params = {}) => {
    if (!gtag) {
      return
    }

    gtag.event(eventName, {
      ...params,
      company: name,
      qualified: isQualified,
    })
  }

  const trackException = (description, fatal) => {
    if (!gtag) {
      return
    }

    gtag.exception({ description, fatal })
  }

  return {
    trackEvent,
    trackException,
  }
}
