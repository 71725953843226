<template>
  <!-- Desktop -->
  <document-scanner-card-modal
    v-if="$vuetify.breakpoint.mdAndUp"
    :opened="opened"
    :front="front"
    :front-label="frontLabel"
    :back="back"
    :back-label="backLabel"
    :disabled="isGettingPermission"
    :stream="stream"
    :is-loading="isLoading"
    :title="title"
    @finish="$emit('finish')"
    @close="$emit('close')"
    @change="$emit('confirm-scanned-document', $event.image, $event.side)"
  />

  <!-- Mobile -->
  <document-scanner-fullscreen
    v-else-if="opened"
    :front="front"
    :front-label="frontLabel"
    :back="back"
    :back-label="backLabel"
    :disabled="isGettingPermission"
    :stream="stream"
    :is-loading="isLoading"
    :title="title"
    @finish="$emit('finish')"
    @close="$emit('close')"
    @change="$emit('confirm-scanned-document', $event.image, $event.side)"
  />
</template>

<script>
import { useCamera } from "@/composables"
import i18n from "@/i18n"

import DocumentScannerCardModal from "./_DocumentScannerCardModal.vue"
import DocumentScannerFullscreen from "./_DocumentScannerFullscreen.vue"

export default {
  name: "DocumentScannerModal",
  components: {
    DocumentScannerCardModal,
    DocumentScannerFullscreen,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    image: {
      type: [String, Object],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    front: {
      type: [String, Object],
      default: "",
    },
    frontLabel: {
      type: [String],
      default: i18n.tc("documentScanner.z-chip_front"),
    },
    back: {
      type: [String, Object],
      default: "",
    },
    backLabel: {
      type: [String],
      default: i18n.tc("documentScanner.z-chip_back"),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { hasPermission, stream, isGettingPermission } = useCamera(true)

    return {
      hasPermission,
      isGettingPermission,
      stream,
    }
  },
}
</script>
