/* eslint-disable max-lines-per-function */
/* eslint-disable camelcase */
import axios from "axios"
import find from "lodash.find"
import get from "lodash.get"

import http from "@/http"
import httpPay from "@/httpPay"
import { changeChunkDeep } from "@/utils/vuex"

const state = {
  billingAddress: {
    zipCode: "",
    address: "",
    addressNumber: "",
    addressComplement: "",
    neighborhood: "",
    cityId: "",
    cityName: "",
    stateId: "",
    stateName: "",
    countryId: "",
    email: "",
  },

  creditCard: {
    number: "",
    cvv: "",
    owner: "",
    expiration: "",
    flag: "",
  },

  registeredCreditCards: [],

  order: {
    id: "",
    status: "",
    closed: false,
    chargesAtCheckin: [],
  },
}

const mutations = {
  changeBillingAddress: changeChunkDeep("billingAddress"),
  changeCreditCardInfo: changeChunkDeep("creditCard"),
  changeOrder: changeChunkDeep("order"),
  changeCards(state, cards) {
    state.registeredCreditCards = cards
  },
}

const actions = {
  async tryFillByZipCode({ commit, dispatch, state, rootState }) {
    const resp = await http.get(
      `/zip/?language=pt&region=br&address=${state.billingAddress.zipCode}`
    )

    if (resp.data.length) {
      const region = resp.data[0]

      await Promise.all([
        dispatch(
          "parameters/getStates",
          { countryCode: "br", section: "billing" },
          { root: true }
        ),
        dispatch(
          "parameters/getCities",
          { stateId: region.stateId, section: "billing" },
          { root: true }
        ),
      ])

      const states = rootState.parameters.statesOptions.billing
      const stateName = get(find(states, { id: region.stateId }), "name") ?? ""

      const cities = rootState.parameters.citiesOptions.billing
      const cityName = get(find(cities, { id: region.cityId }), "name") ?? ""

      commit("changeBillingAddress", {
        stateName,
        stateId: region.stateId || "",
        cityId: region.cityId || "",
        cityName,
        neighborhood: region.neighborhood || "",
        address: region.route || "",
        addressNumber: state.billingAddress?.addressNumber || "",
        addressComplement: state.billingAddress?.addressComplement || "",
      })
    }
  },

  async paymentWithRegistration({ state, rootState }, cardId) {
    const orderId = state.order.id
    const chargeId = state.order.chargesAtCheckin[0]?.id
    const personId = rootState.guest.guest.personId
    const payments = [
      {
        amount: state.order.chargesAtCheckin[0].amount,
        creditCard: {
          cardId,
          operation: "auth_only",
          installments: 1,
        },
        customer: {
          name: `${rootState.guest.guest.name} ${rootState.guest.guest.lastName}`,
          email: rootState.guest.guest.email,
          id: personId,
        },
        id: chargeId,
        items: state.order.chargesAtCheckin[0].items,
        metadata: {
          ...state.order.chargesAtCheckin[0].metadata,
          chargeId,
        },
        method: "creditCard",
        order: {
          id: state.order.id,
          code: rootState.reservation.reservation.reservationNumber,
        },
        status: state.order.chargesAtCheckin[0].status,
        token: state.order.chargesAtCheckin[0].token,
        updatedAt: state.order.chargesAtCheckin[0].updatedAt,
      },
    ]

    const paymentConfigObject = {
      orderId,
      chargeId,
      personId,
      payments,
      isClosed: state.order.closed,
    }

    await http.post("payment/pay", paymentConfigObject)
  },

  async paymentWithoutRegistration({ state, rootState }) {
    const companyId = rootState.company.companyId
    const chargeToken = state.order.chargesAtCheckin[0].token
    const [expirationMonth, expirationYear] =
      state.creditCard.expiration.split("/")

    const states = rootState.parameters.statesOptions.billing
    const stateUF =
      get(find(states, { id: state.billingAddress.stateId }), "uf") ?? ""

    //Obtém o token de acesso da charge
    const { data } = await httpPay.get(
      `/access-token/${companyId}/${chargeToken}`
    )
    const applicationToken = data.token
    const cardConfigObject = {
      type: "card",
      card: {
        number: state.creditCard.number.split(" ").join(""),
        holder_name: state.creditCard.owner,
        exp_month: expirationMonth,
        exp_year: `20${expirationYear}`,
        cvv: state.creditCard.cvv,
        billing_address: {
          line_1: `${state.billingAddress.addressNumber}, ${state.billingAddress.address}, ${state.billingAddress.neighborhood}`,
          line_2: `${state.billingAddress.addressComplement}`,
          zip_code: `${state.billingAddress.zipCode}`,
          city: `${state.billingAddress.cityName}`,
          state: `${stateUF}`,
          country: `${state.billingAddress.countryId}`,
        },
        options: { verify_card: true },
      },
    }

    //cria o token de pagamento
    const response = await axios.post(
      `https://api.mundipagg.com/core/v1/tokens?appId=${applicationToken}`,
      cardConfigObject,
      {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Accept-Language": "en-US,en;q=0.9,pt;q=0.8",
          "Content-Type": "application/json",
        },
      }
    )
    const cardToken = response.data.id

    //realiza o pagamento
    const paymentConfigObject = {
      orderId: state.order.id,
      chargeId: state.order.chargesAtCheckin[0].id,
      personId: null,
      isClosed: state.order.closed,
      payments: [
        {
          id: state.order.chargesAtCheckin[0].id,
          amount: state.order.chargesAtCheckin[0].amount,
          items: state.order.chargesAtCheckin[0].items,
          customer: {
            name: `${rootState.guest.guest.name} ${rootState.guest.guest.lastName}`,
            email: rootState.guest.guest.email,
            phone: rootState.guest.guest.mobile,
            documentTypeId: rootState.guest.guest.documents.documentTypeId,
            documentNumber:
              rootState.guest.guest.documents.documentNumber.replace(
                /[.-]/g,
                ""
              ),
            standalone: true,
            address: {
              line_1: `${state.billingAddress.addressNumber}, ${state.billingAddress.address}, ${state.billingAddress.neighborhood}`,
              line_2: `${state.billingAddress.addressComplement}`,
              zip_code: `${state.billingAddress.zipCode}`,
              city: `${state.billingAddress.cityName}`,
              state: `${stateUF}`,
              country: `${state.billingAddress.countryId}`,
            },
          },
          metadata: {
            ...state.order.chargesAtCheckin[0].metadata,
            chargeId: state.order.chargesAtCheckin[0].id,
          },
          order: {
            id: state.order.id,
            code: rootState.reservation.reservation.reservationNumber,
          },
          status: state.order.chargesAtCheckin[0].status,
          token: state.order.chargesAtCheckin[0].token,
          companyId: rootState.company.companyId,
          groupId: rootState.company.groupId,
          paidAmount: 0,
          method: "creditCard",
          updatedAt: state.order.chargesAtCheckin[0].updatedAt,
          createdAt: state.order.chargesAtCheckin[0].createdAt,
          expiration: state.order.chargesAtCheckin[0].expiration,
          creditCard: {
            cardToken,
            operation: "auth_only",
            installments: 1,
          },
        },
      ],
    }
    await http.post("payment/pay", paymentConfigObject)
  },

  async getCards({ commit, rootState }) {
    const personId = rootState.guest.guest.personId
    const { data } = await httpPay.get(`/customers/${personId}/card`)

    commit("changeCards", data.data)
  },

  async deleteCard({ rootState }, cardId) {
    const personId = rootState.guest.guest.personId

    await httpPay.delete(`/customers/${personId}/card/${cardId}`)
  },

  async registerCard({ state, rootState }) {
    const personId = rootState.guest.guest.personId
    const [expirationMonth, expirationYear] =
      state.creditCard.expiration.split("/")
    const states = rootState.parameters.statesOptions.billing
    const stateUF =
      get(find(states, { id: state.billingAddress.stateId }), "uf") ?? ""

    const { data } = await httpPay.get(`/access-token`)
    const applicationToken = data.token

    const cardConfigObject = {
      type: "card",
      card: {
        number: state.creditCard.number.split(" ").join(""),
        holder_name: state.creditCard.owner,
        exp_month: expirationMonth,
        exp_year: `20${expirationYear}`,
        cvv: state.creditCard.cvv,
        billing_address: {
          line_1: `${state.billingAddress.addressNumber}, ${state.billingAddress.address}, ${state.billingAddress.neighborhood}`,
          line_2: `${state.billingAddress.addressComplement}`,
          zip_code: `${state.billingAddress.zipCode}`,
          city: `${state.billingAddress.cityName}`,
          state: `${stateUF}`,
          country: `${state.billingAddress.countryId}`,
        },
        options: { verify_card: true },
      },
    }

    const response = await axios.post(
      `https://api.mundipagg.com/core/v1/tokens?appId=${applicationToken}`,
      cardConfigObject,
      {
        headers: {
          Accept: "application/json, text/plain, */*",
          "Accept-Language": "en-US,en;q=0.9,pt;q=0.8",
          "Content-Type": "application/json",
        },
      }
    )
    const cardToken = response.data.id

    await httpPay.post(`/customers/${personId}/card`, {
      billing_address: {
        ...cardConfigObject.card.billing_address,
        //necessário pois API retorna 422 ao usar o nome do estado
        state: stateUF,
      },
      token: cardToken,
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
