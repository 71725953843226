import { createI18nMessage } from "@vuelidate/validators"
import map from "lodash.map"

import i18n from "@/i18n"

const withI18nMessage = createI18nMessage({
  t: i18n.t.bind(i18n),
})

const getErrorMessages = (field) => {
  if (field) {
    return map(field.$errors, (err) => err.$message)
  }
  return []
}

/**
 *
 * @returns
 */
export function useValidations() {
  return {
    withI18nMessage,
    getErrorMessages,
  }
}
