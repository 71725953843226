<template>
  <z-card-modal title="Assinatura" width="700" :opened="opened" persistent>
    <template #content>
      <div class="d-flex flex-column align-center mt-4">
        <p class="body-text">
          {{ $t("reservation.confirmation_conclusion") }}
        </p>
        <vue-signature-pad
          ref="signaturePad"
          width="100%"
          height="200px"
          :custom-style="{ 'border-bottom': '1px solid black' }"
          :options="{
            onBegin: () => {
              $refs.signaturePad.resizeCanvas()
            },
          }"
        />
        <z-btn
          class="mt-6"
          :text="$t('reservation.clean_signature')"
          icon="$close_rounded"
          width="350px"
          @click="clean"
        />
        <div class="my-6" style="border-top: 1px solid #ddd; width: 100%" />
      </div>
      <div class="d-flex justify-end">
        <z-btn
          class="mr-3"
          :text="$t('reservation.back_button')"
          width="130px"
          :disabled="isLoading"
          @click="$emit('back')"
        />
        <z-btn
          :text="$t('reservation.advance_button')"
          width="130px"
          primary
          :is-loading="isLoading"
          @click="save"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
import { ref } from "vue"

export default {
  model: {
    prop: "opened",
    event: "change",
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const signaturePad = ref(null)

    const clean = () => {
      signaturePad.value.clearSignature()
    }

    const save = () => {
      const { isEmpty, data } = signaturePad.value.saveSignature()

      if (!isEmpty) {
        emit("change", data)
      }
    }

    return { signaturePad, save, clean }
  },
}
</script>
