export const TermTypes = {
  Use: 1,
  Privacy: 2,
}

export const Document = (main) => ({
  documentTypeId: 1,
  back: "",
  backImage: "",
  backImageMemory: "",
  front: "",
  frontImage: "",
  frontImageMemory: "",
  documentNumber: "",
  main: main,
})

export const Term = (name, type) => ({
  name,
  type,
  id: "",
  isChecked: false,
})

export const Optins = () => [
  { name: "whatsapp", type: 4, isChecked: false },
  { name: "email", isChecked: false },
]

export const Guest = () => ({
  masterGuest: false,
  informSocialName: false,
  isChild: false,
  vip: false,
  mobileCountryCode: "BR",
  mobileCityCode: "",
  mobilePhoneNumber: "55",
  mobilePhoneCountryCode: "",
  name: "",
  lastName: "",
  extraFields: {},
  socialName: "",
  email: "",
  gender: "",
  ddi: "",
  ddd: "",
  ddiMobile: null,
  dddMobile: null,
  currentImage: "",
  id: "",
  birthdateDay: "",
  birthdateMonth: "",
  birthdateYear: "",
  biometricsAccepted: null,
  birthdate: "",
  jobId: "",
  jobName: "",
  nationalityId: "BR",
  nationalityName: "Brasil",
  addressNumber: "",
  addressComplement: "",
  zipCode: "",
  cityId: "",
  cityName: "",
  stateId: "",
  stateName: "",
  countryId: "BR",
  countryName: "Brasil",
  phoneNumber: "",
  neighborhood: "",
  phoneCountryCode: "",
  phoneCityCode: "",
  photoUrl: "",
  photoUrlMemory: "",
  photoId: "",
  personId: "",
  arrivingFromCountryId: "",
  arrivingFromCountryName: "",
  arrivingFromStateId: "",
  arrivingFromStateName: "",
  arrivingFrom: "",
  arrivingFromName: "",
  address: "",
  purposeTrip: "",
  purposeTripName: "",
  arrivingBy: "",
  arrivingByName: "",
  nextDestination: "",
  nextDestinationName: "",
  nextDestinationStateId: "",
  nextDestinationStateName: "",
  nextDestinationCountryId: "",
  nextDestinationCountryName: "",
  signatureId: "",
  signatureUrl: "",
  upload: {
    front: {
      fileName: "",
      src: "",
    },
    back: {
      fileName: "",
      src: "",
    },
  },
  documents: Document(true),
  additionalDocument: Document(false),
  additionalData: {},
  terms: [Term("use-terms", 1), Term("privacy", 2)],
  optins: Optins(),
})
