<template>
  <z-card
    v-click-outside="() => $emit('close')"
    width="100%"
    class="my-reservation"
  >
    <template #content>
      <template v-if="$vuetify.breakpoint.smAndDown">
        <div class="card-title grid-span-2">
          {{ $t("reservation.reservation_info") }}
        </div>
        <div
          v-for="(info, index) of reservationInfo"
          :key="'reservation-info' + index"
          class="my-reservation-info"
        >
          <z-icon color="var(--primary)">{{ info.icon }}</z-icon>
          <div>
            <div v-if="info.label" class="body3">{{ info.label }}</div>
            <div
              v-if="info.value"
              class="body3-bold"
              :class="{ 'link-value': info.onClick }"
              @click="info.onClick ? info.onClick : () => {}"
            >
              {{ info.value }}
            </div>
            <div v-if="info.complement" class="body3">
              {{ info.complement }}
            </div>
          </div>
        </div>

        <v-divider class="grid-span-2"></v-divider>

        <div class="card-title grid-span-2">
          {{ $t("reservation.hotel_info") }}
        </div>
        <div
          v-for="(info, index) of hotelInfo"
          :key="'hotel-info' + index"
          class="my-reservation-info grid-span-2"
        >
          <z-icon color="var(--primary)">{{ info.icon }}</z-icon>
          <div>
            <div v-if="info.label" class="body3">{{ info.label }}</div>
            <div v-if="info.value" class="body3-bold">{{ info.value }}</div>
            <div v-if="info.complement" class="body3">
              {{ info.complement }}
            </div>
          </div>
        </div>
        <div
          id="reservation-map"
          class="map-container"
          style="display: none"
        ></div>
      </template>
      <template v-else>
        <div class="card-title grid-span-2">
          {{ $t("reservation.reservation_info") }}
        </div>
        <div
          v-for="(info, index) of allInfos"
          :key="index"
          class="my-reservation-info"
        >
          <z-icon color="var(--primary)">{{ info.icon }}</z-icon>
          <div>
            <div v-if="info.label" class="body3">{{ info.label }}</div>
            <div v-if="info.value" class="body3-bold">{{ info.value }}</div>
            <div v-if="info.complement" class="body3">
              {{ info.complement }}
            </div>
          </div>
        </div>
        <div
          id="reservation-map"
          class="map-container"
          style="display: none"
        ></div>
      </template>
    </template>
  </z-card>
</template>

<script>
import dayjs from "dayjs"
import { onMounted, toRefs } from "vue"

import i18n from "@/i18n"

export default {
  props: {
    hotel: {
      type: Object,
      required: true,
    },
    reservation: {
      type: Object,
      required: true,
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  // eslint-disable-next-line
  setup(props) {
    const { hotel, reservation } = toRefs(props)
    // let isLoaded = false

    onMounted(() => {
      // setTimeout(() => {
      //   if (!isLoaded) {
      //     const latitudeLongitude = [-22.97368, -43.37406]
      //     const map = L.map("reservation-map").setView(latitudeLongitude, 12)
      //     L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      //       maxZoom: 19,
      //     }).addTo(map)
      //     L.marker(latitudeLongitude).addTo(map)
      //     isLoaded = true
      //   }
      // }, 500)
    })

    const checkin = dayjs(
      reservation.value.checkinDate.replace("Z", "")
    ).startOf("day")
    const checkout = dayjs(
      reservation.value.checkoutDate.replace("Z", "")
    ).startOf("day")

    const nights = checkout.diff(checkin, "days")
    const phoneNumber = hotel.value.phoneNumber

    const onClickAddress = () => {}

    return {
      allInfos: [
        {
          icon: "$assignment_rounded",
          label: i18n.tc("reservation.reservation_number").toUpperCase(),
          value: reservation.value.reservationNumber,
        },
        {
          icon: "$business_rounded",
          label: i18n.tc("reservation.hotel").toUpperCase(),
          value: hotel.value.nomeFantasia,
          complement: "",
        },
        {
          icon: " $room_service_rounded",
          label: i18n.tc("reservation.check_in").toUpperCase(),
          value: checkin.format("DD/MM/YYYY"),
          complement: hotel.value.minCheckin
            ? `${i18n.tc("reservation.from")} ${hotel.value.minCheckin}h`
            : "",
        },
        {
          icon: "$plane",
          label: i18n.tc("reservation.check_out").toUpperCase(),
          value: checkout.format("DD/MM/YYYY"),
          complement: hotel.value.maxCheckout
            ? `${i18n.tc("reservation.until")} ${hotel.value.maxCheckout}h`
            : "",
        },
        {
          icon: "$bed",
          label: i18n.tc("reservation.nights").toUpperCase(),
          value: `${nights} noite(s)`,
        },
        {
          icon: "$phone_rounded",
          label: phoneNumber,
          value: "",
        },
        {
          icon: "$mail_rounded",
          label: hotel.value.email,
        },
        {
          icon: "$location_on_rounded",
          label: hotel.value.fullAddress,
          onClick: onClickAddress,
        },
      ],
      hotelInfo: [
        {
          icon: "$business_rounded",
          label: i18n.tc("reservation.hotel").toUpperCase(),
          value: hotel.value.nomeFantasia,
          complement: "",
        },
        {
          icon: "$phone_rounded",
          label: phoneNumber,
          value: "",
        },
        {
          icon: "$mail_rounded",
          label: hotel.value.email,
        },
        {
          icon: "$location_on_rounded",
          label: hotel.value.fullAddress,
        },
      ],
      reservationInfo: [
        {
          icon: "$assignment_rounded",
          label: i18n.tc("reservation.reservation_number").toUpperCase(),
          value: reservation.value.reservationNumber,
        },
        {
          icon: "$bed",
          label: i18n.tc("reservation.nights").toUpperCase(),
          value: `${nights} noite(s)`,
        },
        {
          icon: " $room_service_rounded",
          label: i18n.tc("reservation.check_in").toUpperCase(),
          value: checkin.format("DD/MM/YYYY"),
          complement: hotel.value.minCheckin
            ? `${i18n.tc("reservation.from")} ${hotel.value.minCheckin}h`
            : "",
        },
        {
          icon: "$plane",
          label: i18n.tc("reservation.check_out").toUpperCase(),
          value: checkout.format("DD/MM/YYYY"),
          complement: hotel.value.maxCheckout
            ? `${i18n.tc("reservation.until")} ${hotel.value.maxCheckout}h`
            : "",
        },
      ],
      onClickAddress,
    }
  },
}
</script>

<style lang="scss" scoped>
.my-reservation {
  display: grid;
  grid-template-columns: 2fr 2fr;
  column-gap: 15px;
  row-gap: 15px;
  // max-width: 700px;
  max-width: 500px;
  margin: auto;
}

.my-reservation-info {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  align-items: center;
  word-break: break-word;
}

.grid-span-2 {
  grid-column: span 2;
}

.hotel-info {
  display: grid;
}

.link-value {
  color: var(--link-color);
  text-decoration: underline;
}

.map-container {
  grid-column: 3 / -1;
  grid-row: 1 / 6;
}

@media screen and (max-width: 786px) {
  .map-container {
    display: none;
  }

  .my-reservation {
    grid-template-columns: 2fr 2fr;
  }

  .map-container {
    grid-column: 1 / -1;
    grid-row: span 3;
  }
}

@media screen and (min-width: 1024px) {
  .map-container {
    height: 100%;
  }
}
</style>
