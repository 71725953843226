<template>
  <z-card-modal
    :width="$vuetify.breakpoint.smAndDown ? '400px' : '700px'"
    :opened="opened"
    persistent
    widthclass="z-card-container"
  >
    <template #content>
      <div class="d-flex justify-center flex-column mt-5">
        <section class="main-content-modal">
          <div class="checklist-img">
            <img src="@/assets/illustrations/onboarding-fnrh.svg" />
          </div>
          <div class="text-content">
            <h4 class="body-text text-center my-5">
              {{ $t("onboarding.modal_title") }}
            </h4>
            <p
              class="body-text text-center"
              v-html="$t('onboarding.fnrh_term')"
            ></p>
          </div>
        </section>

        <div class="modal-btn-container">
          <z-btn
            class="modal-btn"
            primary
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '80%'"
            :text="$t('modal.button_ok')"
            @click="$emit('click:close')"
          />
        </div>
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.checklist-img {
  text-align: center;

  img {
    width: 55%;
  }
}

.modal-btn {
  margin-top: 20px;
}

@media screen and (min-width: 786px) {
  .modal-btn-container {
    text-align: end;
    justify-self: end;
  }

  .checklist-img {
    width: 60%;
    img {
      width: 100%;
    }
  }

  .text-content {
    width: 50%;
    margin-top: 10px;
    padding: 0 10px;
  }

  .modal-btn {
    width: 145px !important;
    margin-top: 0px;
  }

  .main-content-modal {
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>
