import { DocumentTypeValues } from "@/consts"
import http from "@/http"
import { postDocumentWithPresignedURL } from "@/utils/upload"
import { changeChunkDeep } from "@/utils/vuex"

const state = {
  resend: {
    fnrhId: "",
    guestId: "",
    reservationId: "",
    documentTypeId: DocumentTypeValues.CPF,
    documentNumber: "",
    document: {
      front: "",
      back: "",
    },
  },
}

const mutations = {
  changeResend: changeChunkDeep("resend"),
}

const actions = {
  async resendDocuments({ state }) {
    const document = {
      main: true,
      documentTypeId: state.resend.documentTypeId,
      documentNumber: state.resend.documentNumber,
    }

    if (state.resend.document.front && state.resend.document.back) {
      const [frontResp, backResp] = await Promise.all([
        postDocumentWithPresignedURL({
          path: "resend-front",
          src: state.resend.document.front,
        }),
        postDocumentWithPresignedURL({
          path: "resend-back",
          src: state.resend.document.back,
        }),
      ])

      document["front"] = frontResp.key
      document["back"] = backResp.key
    }

    await http.put("/clients/replace-documents", {
      fnrhId: state.resend.fnrhId,
      guestId: state.resend.guestId,
      documents: [document],
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
