import alerts from "./es/alerts.json"
import documentScanner from "./es/documentScanner.json"
import error from "./es/error.json"
import guest from "./es/guest.json"
import modal from "./es/modal.json"
import onboarding from "./es/onboarding.json"
import options from "./es/options.json"
import parameters from "./es/parameters.json"
import recognition from "./es/recognition.json"
import reservation from "./es/reservation.json"
import validations from "./es/validations.json"
import warrantyRegister from "./es/warrantyRegister.json"
import welcome from "./es/welcome.json"

export default {
  alerts,
  error,
  guest,
  modal,
  welcome,
  onboarding,
  reservation,
  parameters,
  validations,
  recognition,
  documentScanner,
  warrantyRegister,
  options,
}
