import { setPreventStartFlow } from "./guardsSharedState"

// Array<Regex>
const AllowedRoutes = [/^\/document-scan\/.+/g, /^\/error\//, /^\/checkout\/?/]

export function isAllowedEntryRoute(to, _, next) {
  if (AllowedRoutes.some((route) => route.test(to.path))) {
    setPreventStartFlow(true)
  }
  next()
}
