<template>
  <z-card-modal :opened="opened" persistent :title="title" width="350">
    <template #content>
      <div class="main-content-modal my-2">
        <z-icon color="var(--red-500)" size="80">$x_in_a_circle</z-icon>
        <p class="body-text text-center my-6">
          {{ text }}
        </p>
        <z-btn
          primary
          width="100%"
          data-cy="incorrect-modal-btn"
          :text="$t('reservation.btn_ok')"
          @click="$emit('click:close')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content-modal {
  display: flex;
  flex-direction: column;

  align-items: center;
}
</style>
