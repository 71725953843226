<template>
  <z-card shadow :width="enableClose ? '290px' : '260px'">
    <template #content>
      <section v-if="!enableClose" class="main-content">
        <img class="image-doc mr-2" :src="image" />
        <a>
          <p class="ml-3 body-text">{{ imageSource }}</p>
        </a>
        <span v-if="enableClose" @click="$emit('click:erase')"
          ><z-icon> $close_rounded </z-icon></span
        >
      </section>
      <section v-else class="main-content-enabled-close">
        <img class="image-doc" :src="image" />
        <a>
          <p class="body-text">{{ imageSource }}</p>
        </a>
        <z-icon
          v-if="enableClose"
          :tooltip="$t('documentScanner.erase')"
          @click="$emit('click:erase')"
        >
          $close_rounded
        </z-icon>
      </section>
    </template>
  </z-card>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    imageSource: {
      type: String,
      default: "",
    },
    enableClose: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content-enabled-close {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.image-doc {
  width: 30px;
  height: 30px;
  margin: auto 0;
  // transform: rotate(-360deg);
}
</style>
