import store from "@/store"
import { isValidUUID } from "@/utils/uuid"

import {
  isInitialConfigLoaded,
  preventStartFlow,
  setInitalConfigLoaded,
} from "./guardsSharedState"

/**
 * Guarda de rota para pegar as configurações iniciais
 */
// eslint-disable-next-line
export async function getInitialConfig(to, __, next) {
  if (isInitialConfigLoaded) {
    next()
    return
  }

  try {
    await store.dispatch("getInitialConfig", {
      guestId: getGuestID(to),
      reservationId: to.params.reservationId,
      origin: to.query.origin,
      lang: to.query.lang,
    })
    setInitalConfigLoaded(true)

    if (!preventStartFlow)
      next({ name: store.state.flow.reservation.currentStep.route })
    else next()
  } catch (e) {
    setInitalConfigLoaded(true)
    if (e?.response?.status === 302) {
      // continua carregando
      return
    }
    if (e?.response?.status === 404) {
      next({ name: "404", query: { redirectUrl: document.location.pathname } })
    } else {
      next({ name: "500", query: { redirectUrl: document.location.pathname } })
      console.error(e)
    }
  }
}

/**
 * Pega o guestID se for passado na URL
 * @returns guestId
 */
function getGuestID(to) {
  const urlParams = new URLSearchParams(location.search)
  let guestId = to.params.guestId || urlParams.get("guestId")

  return isValidUUID(guestId) ? guestId : undefined
}
