<template>
  <z-card
    class="reservation-sidebar"
    width="100%"
    style="box-shadow: none !important"
    :shadow="false"
  >
    <template #content>
      <z-btn
        v-if="$vuetify.breakpoint.smAndDown"
        style="justify-self: start"
        small
        rounded
        remove-bg
        primary
        color="var(--black-300)"
        icon="$chevron_left_rounded"
        :text="$t('guest.guest_list')"
        @click="$emit('click:back')"
      />

      <z-btn
        v-else
        small
        rounded
        :text="$t('guest.guest_list')"
        icon="$chevron_left_rounded"
        @click="$emit('click:back')"
      />

      <div v-if="$vuetify.breakpoint.mdAndUp" class="divider"></div>
      <z-stepper
        class="smck-stepper"
        style="box-shadow: none !important"
        :alt-labels="$vuetify.breakpoint.smAndDown"
        :is-vertical="$vuetify.breakpoint.mdAndUp"
        :steppers="currentSteps"
        :value="
          currentSteps.findIndex((s) => s.route === currentStep.route) + 1
        "
      ></z-stepper>
    </template>
  </z-card>
</template>

<script>
import { computed, toRefs } from "vue"

import i18n from "@/i18n"
import { StepType } from "@/store/flow/step"

export default {
  props: {
    currentStep: {
      type: Object,
      required: true,
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { steps } = toRefs(props)

    const currentSteps = computed(() => {
      return steps.value
        .filter((step) => step.type !== StepType.Extra)
        .map((step, index) => ({
          route: step.route,
          id: index + 1,
          label: i18n.tc(step.name) || "",
          icon: "$check_rounded",
        }))
    })

    return {
      currentSteps,
    }
  },
}
</script>

<style lang="scss" scoped>
.reservation-sidebar {
  display: grid;
  row-gap: 15px;
  padding: 15px;
  box-shadow: none !important;
}

.divider {
  height: 1px;
  background-color: var(--black-100);
}

.smck-stepper {
  box-shadow: none !important;

  :deep(.v-stepper__label) {
    max-width: 12ch;
  }
}

@media screen and (max-width: 960px) {
  .reservation-sidebar {
    background: var(--background) !important;
    padding: 0 !important;
  }

  .reservation-sidebar {
    :deep(.v-stepper__header) {
      box-shadow: none !important;
    }

    :deep(.v-stepper__step) {
      padding: 0px 0 24px 0 !important;
    }

    :deep(.v-divider) {
      margin: 11px -24px 0;
    }
  }
}
</style>
