var render = function render(){var _vm=this,_c=_vm._self._c;return _c('z-card',{staticClass:"smck-guest-card",attrs:{"data-cy":"guest-card","width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : '420px',"shadow":_vm.$vuetify.breakpoint.mdAndUp},on:{"click":_vm.onClickCard},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"smck-guest-card-header"},[(_vm.isMainGuest)?_c('z-chip',{ref:"mainGuest",attrs:{"selected":"","x-small":"","text":_vm.$t('reservation.chip_main_guest'),"color":_vm.$zoox.colors.black300}}):_c('z-chip',{ref:"nonMainGuest",attrs:{"x-small":"","text":"Acompanhante"}}),(!_vm.completed && _vm.wasNotified)?_c('z-chip',{ref:"waiting",attrs:{"x-small":"","selected":"","text":_vm.$t('reservation.chip_waiting'),"color":_vm.$zoox.colors.orange}}):_vm._e(),(_vm.completed)?_c('z-chip',{ref:"completed",attrs:{"x-small":"","selected":"","text":_vm.$t('reservation.chip_completed'),"color":_vm.$zoox.colors.lightgreen}}):_vm._e()],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"smck-guest-card-content"},[_c('z-avatar',{staticClass:"smck-guest-card-avatar",attrs:{"size":"64px","photo":_vm.avatar}}),_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"body3-bold"},[_vm._v(_vm._s(_vm.contact || _vm.$t("guest.no_email")))])],1),(
        _vm.$vuetify.breakpoint.smAndDown &&
        !_vm.completed &&
        _vm.guestCardSelected === _vm.index
      )?_c('z-btn',{attrs:{"data-cy":"guest-card-fill","primary":"","is-loading":_vm.isLoading,"text":_vm.$t('reservation.fill_guest_record')},on:{"click":function($event){return _vm.$emit('click:guest-card-fill')}}}):_vm._e(),(
        _vm.$vuetify.breakpoint.smAndDown &&
        _vm.completed &&
        _vm.guestCardSelected === _vm.index
      )?_c('z-btn',{attrs:{"primary":"","text":_vm.$t('guest.edit_data'),"is-loading":_vm.isLoading},on:{"click":function($event){return _vm.$emit('click:guest-card-edit')}}}):_vm._e()]},proxy:true},(_vm.$vuetify.breakpoint.mdAndUp)?{key:"actions",fn:function(){return [(!_vm.isLoading)?_c('z-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('z-icon',_vm._g(_vm._b({attrs:{"data-cy":"edit-btn"},on:{"click":function($event){return _vm.$emit('click:guest-card-edit')}}},'z-icon',attrs,false),on),[_vm._v(" $edit_rounded ")])]}}],null,false,3876861357)},[_c('p',{staticClass:"body-text"},[_vm._v(_vm._s(_vm.$t("guest.edit_information")))])]):_c('z-circular-progress',{attrs:{"color":"var(--primary)","value":"10","size":"20","width":"5","indeterminate":"","rotate":""}}),(_vm.renderSendNotificationButton && !_vm.completed)?_c('z-icon',{staticClass:"ml-2",attrs:{"data-cy":"send-notification"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click:guest-card-notification')}}},[_vm._v(" $share_rounded ")]):_vm._e()]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }