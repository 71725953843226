import dayjs from "dayjs"
import find from "lodash.find"
import merge from "lodash.merge"
import omit from "lodash.omit"
import omitBy from "lodash.omitby"
import some from "lodash.some"

import { Document, Optins, Term, TermTypes } from "@/models"

const AllowedNullOrEmpty = [
  "jobId",
  "phoneNumber",
  "ddi",
  "ddd",
  "phoneCountryCode",
]

function getPhone(phoneNumber) {
  const chunks = phoneNumber.split(" ")
  let ddi
  let ddd
  let number

  if (chunks.length < 3) {
    console.error("Invalid phone number")

    return {
      ddi: "",
      ddd: "",
      number: "",
      countryCode: "",
    }
  }

  ddi = chunks[0].replace(/[^0-9]/g, "")
  ddd = chunks[1].replace(/[^0-9]/g, "")
  number = chunks
    .slice(2, chunks.length)
    .join("")
    .replace(/[^0-9]/g, "")

  return { ddi, ddd, number }
}

function toPhoneNumber(ddi, ddd, phoneNumber) {
  if (!ddi || !ddd || !phoneNumber) return ""

  return `${ddi} ${ddd} ${phoneNumber}`
}

/**
 * Função que formata o guest antes de enviar o objeto para o servidor
 *
 * @param {Guest} guest - guest sem estar no formato de enviar para o server
 */
// eslint-disable-next-line
export function formatGuestToSend(guest, { countries = [], jobs = [] } = {}) {
  const job = find(jobs, { _id: guest.jobId })
  const documents = []

  let guestCopy = Object.assign({}, guest)

  guestCopy.birthdate = dayjs(guestCopy.birthdate, "YYYY-MM-DD").format(
    "YYYY-MM-DD"
  )

  if (guest.mobilePhoneNumber) {
    const { ddd, ddi, number } = getPhone(guest.mobilePhoneNumber, {
      countries,
    })

    guestCopy.mobileCountryCode = ddi
    guestCopy.mobileCityCode = ddd
    guestCopy.mobilePhoneNumber = number
    guestCopy.mobilePhoneCountryCode = guest.mobilePhoneCountryCode
  }

  if (guest.phoneNumber) {
    const { ddd, ddi, number } = getPhone(guest.phoneNumber, {
      countries,
    })

    guestCopy.phoneCountryCode = guest.phoneCountryCode
    guestCopy.ddi = ddi
    guestCopy.ddd = ddd
    guestCopy.phoneNumber = number
  } else {
    guestCopy.phoneNumber = ""
    guestCopy.ddi = ""
    guestCopy.ddd = ""
    guestCopy.phoneCountryCode = ""
  }

  if (job?.name !== guestCopy.jobName) {
    guestCopy.jobId = null
  }

  guestCopy = omitBy(
    guestCopy,
    (value, key) =>
      (value === "" && !some(AllowedNullOrEmpty, (k) => k === key)) ||
      (value === null && !some(AllowedNullOrEmpty, (k) => k === key))
  )

  guestCopy = omitFrontendOnlyFields(guestCopy)

  if (
    guest.documents.documentNumber != null &&
    guest.documents.documentTypeId != null
  ) {
    documents.push({
      main: true,
      back: guest.documents.back,
      documentNumber: guest.documents.documentNumber,
      documentTypeId: guest.documents.documentTypeId,
      front: guest.documents.front,
    })
  }

  if (
    guest.additionalDocument.documentTypeId &&
    guest.additionalDocument.documentNumber
  ) {
    documents.push({
      main: false,
      back: guest.additionalDocument.back,
      documentNumber: guest.additionalDocument.documentNumber,
      documentTypeId: guest.additionalDocument.documentTypeId,
      front: guest.additionalDocument.front,
    })
  }

  const mainDocument = find(documents, { main: true })

  return Object.assign(guestCopy, {
    documents,
    documentTypeId: mainDocument?.documentTypeId,
    documentNumber: mainDocument?.documentNumber,
    // Campo opcional, tem que permitir sem limpado
    phoneNumber: guestCopy.phoneNumber ?? "",
    ddi: guestCopy.ddi ?? "",
    ddd: guestCopy.ddd ?? "",
    phoneCountryCode: guestCopy.phoneCountryCode ?? "",
  })
}

function omitFrontendOnlyFields(guest) {
  return omit(guest, [
    "birthdateDay",
    "birthdateMonth",
    "birthdateYear",
    "upload",
    "currentImage",
    "photoUrlMemory",
    "phoneMask",
    "additionalDocumentNumber",
    "additionalDocumentTypeId",
    "additionalDocument",
  ])
}

function buildMainDocument(guest) {
  const { documentNumber, documentTypeId } = guest

  const mainDocument = find(guest.documents, { main: true })

  if (mainDocument == null) {
    if (documentNumber == null || documentTypeId == null) return {}

    return {
      main: true,
      documentNumber,
      documentTypeId,
    }
  }

  return mainDocument
}

/**
 * Função que formata o guest quando lê o objeto do servidor
 *
 * @param {*} guest
 */
export function readGuestFromServer(guest, { additionalData = {} } = {}) {
  const birthdateData = {
    birthdate: "",
    birthdateDay: "",
    birthdateMonthDay: "",
    birthdateMonth: "",
    birthdateYear: "",
  }

  if (guest.birthdate) {
    const guestBirthdate = dayjs(
      guest.birthdate.replace(/Z$/, ""),
      "YYYY-MM-DD"
    )

    if (guestBirthdate.isValid()) {
      birthdateData.birthdate = guestBirthdate.format("YYYY-MM-DD")
      birthdateData.birthdateMonthDay = guestBirthdate.get("month") + 1
      birthdateData.birthdateYear = guestBirthdate.get("year")
      birthdateData.birthdateDay = formatDateNumber(guestBirthdate.get("date"))
      birthdateData.birthdateMonth = formatDateNumber(
        birthdateData.birthdateMonthDay
      )
    }
  }

  const mainDocument = buildMainDocument(guest)
  const additionalDocument = find(guest.documents, { main: false })

  const guestUseTerm = find(guest.terms, { type: TermTypes.Use })
  const useTerms = merge(Term("use-terms", 1), guestUseTerm)

  const guestPrivacyTerm = find(guest.terms, { type: TermTypes.Privacy })
  const privacyTerms = merge(Term("privacy", 2), guestPrivacyTerm)

  let guestCopy = omit(guest, ["documents"])

  return merge(guestCopy, {
    ...birthdateData,
    addressNumber: guest.addressNumber || "",
    phoneNumber: toPhoneNumber(guest.ddi || "55", guest.ddd, guest.phoneNumber),
    documents: merge(Document(true), mainDocument),
    additionalDocument: merge(Document(false), additionalDocument),
    additionalData: merge(additionalData, guest.additionalData || {}),
    mobilePhoneNumber: toPhoneNumber(
      guest.mobileCountryCode,
      guest.mobileCityCode,
      guest.mobilePhoneNumber
    ),
    terms: [useTerms, privacyTerms],
    optins: merge(Optins(), guest.optins || {}),
    photoUrlMemory: "",
  })
}

/**
 * Faz o tratamento de adicionar um 0 antes (01)
 */
function formatDateNumber(number) {
  if (number < 0 || isNaN(number)) {
    throw new Error("Invalid date number!")
  }
  return +number >= 10 ? String(number) : "0" + number
}
