<template>
  <z-card-modal width="470px" persistent :opened="opened">
    <template #content>
      <div class="pass-hospitality document-scanner d-flex flex-column">
        <div class="camera-stream-video">
          <z-camera-stream
            ref="cameraStream"
            mirror
            :stream="stream"
            height="100%"
          />
        </div>
        <div
          v-if="isLoadingFace"
          style="
            background-color: black;
            height: 330px;
            position: absolute;
            width: 93%;
            margin-bottom: 12%;
            margin-top: 10%;
            opacity: 0.5;
          "
        ></div>

        <div class="mb-2">
          <img
            v-if="!isLoadingFace"
            class="face-target"
            src="@/assets/images/mira-horizontal.svg"
            alt="Document target"
          />
          <div
            v-if="isGettingPermission"
            ref="test"
            class="face-scan-instructions"
          ></div>
          <img
            v-if="!isLoadingFace"
            class="face-target-union"
            src="@/assets/images/Union.svg"
            alt="Document target"
          />
        </div>
        <animated-loading v-if="isLoadingFace" class="waiting-loading" />
      </div>
      <div class="d-flex flex-column align-center">
        <div ref="test" class="face-scan-instructions-text body3">
          <p v-if="!isGettingPermission" class="body-text my-4">
            {{ $t("recognition.facial_takepic_instruct") }}
          </p>
          <p v-else class="my-4 body-text">
            {{ $t("recognition.facial_takepic_instruct2") }}
          </p>
        </div>

        <section class="align-self-end">
          <z-btn
            class="document-scan-btn mx-3"
            :text="$t('recognition.facial_takepic_buttonCancel')"
            :disabled="isLoadingFace"
            @click="$emit('close')"
          />
          <z-btn
            data-cy="take-pic-btn"
            class="document-scan-btn"
            :text="actionBtnText"
            primary
            :disabled="isLoadingFace"
            @click="onScanClick"
          />
        </section>
      </div>
    </template>
  </z-card-modal>
</template>
<script>
import { ref } from "vue"

import { useCamera } from "@/composables"

export default {
  name: "TakePictureModal",
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    actionBtnText: {
      type: String,
      default: "Capturar",
    },
    isLoadingFace: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { hasPermission, stream, isGettingPermission } = useCamera()
    const cameraStream = ref(null)

    const onScanClick = async () => {
      const image = await cameraStream.value.getImage()
      emit("scan:click", image)
    }

    return {
      cameraStream,
      hasPermission,
      isGettingPermission,
      stream,
      onScanClick,
    }
  },
}
</script>

<style lang="scss">
.pass-hospitality {
  &.document-scanner {
    video {
      height: 360px !important;
      width: 100% !important;
    }
  }
}
.camera-stream-video {
  width: 100%;
  .error-text {
    visibility: hidden !important;
  }
}
</style>

<style lang="scss" scoped>
.pass-hospitality {
  &.document-scanner {
    align-items: center;
    justify-content: center !important;
    height: 330px !important;
    width: 100%;
    background-color: #666666;
    padding-top: 12px;

    .document-scan-btn {
      width: 80%;
      justify-self: center;
      margin-bottom: 20px;
    }

    .face-scan-instructions {
      display: flex;
      align-content: center;
      justify-content: center;
      text-align: center;
    }

    .face-scan-instructions-text {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .face-target {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: 18px auto;
      width: 75%;
    }

    .face-target-union {
      position: absolute;
      left: 0;
      right: 0;
      top: 2;
      bottom: 0;
      margin: auto;
      width: 40%;
      margin-bottom: 30%;
    }
    .waiting-loading {
      position: absolute !important;
      left: 0;
      right: 0;
      top: 2;
      bottom: 0;
      margin: auto;
      width: 35%;
      margin-bottom: 35%;
    }
  }
}
</style>
