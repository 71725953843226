export const StepType = {
  /**
   * Não aparece na lista de steps, mas é chamado na sequência estipulada
   */
  Extra: 1,
  /**
   * Aparece em todos os casos (default)
   */
  Default: 3,
}

export const StepVisualization = {
  Any: 1,
  AdultOnly: 2,
  MasterGuestOnly: 3,
}

/**
 * Factory de objeto do tipo step.
 *
 * @param {*} route - Rota do step em questão
 * @param {*} name - Nome do step que vai ficar visível para o usuário
 * @param {*} type - Meta-dado relacionado ao step
 * @returns
 */
export const Step = (
  route,
  name,
  params = {},
  type = StepType.Default,
  visualization = StepVisualization.Any
) => ({
  name,
  route,
  params,
  type,
  visualization,
})
