export let isInitialConfigLoaded = false
export let preventStartFlow = false

export function setInitalConfigLoaded(value) {
  isInitialConfigLoaded = value
}

export function setPreventStartFlow(value) {
  preventStartFlow = value
}
