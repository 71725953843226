<template>
  <div id="recaptcha"></div>
</template>

<script>
import { load } from "recaptcha-v3"
import { onBeforeUnmount, onMounted, reactive } from "vue"

export default {
  setup(_, { emit }) {
    const controller = reactive({
      recaptcha: null,
      interval: null,
    })

    const watchRecaptchaResult = () => {
      if (controller.recaptcha !== null) {
        const token = controller.recaptcha.getResponse()

        if (token) {
          clearInterval(controller.interval)
          emit("resolved")
        }
      }
    }

    onMounted(async () => {
      const recaptchaResp = await load(process.env.VUE_APP_RECAPTCHA_KEY, {
        explicitRenderParameters: {
          container: "recaptcha",
        },
      })

      controller.recaptcha = recaptchaResp.recaptcha
      controller.interval = setInterval(watchRecaptchaResult, 1000)
    })

    onBeforeUnmount(() => {
      clearInterval(controller.interval)
    })

    return {}
  },
}
</script>

<style lang="scss">
#recaptcha {
  width: 200px;
  height: 100px;
}
</style>
