var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{class:{ 'app-loading': _vm.$route.name === null }},[(_vm.$route.name === null)?_c('div',[_c('z-loading',{staticClass:"loading",attrs:{"colors":[
        'var(--black-050)',
        'var(--black-100)',
        'var(--black-100)',
        'var(--black-300)',
        'var(--black-300)',
        'var(--black-800)',
        'var(--black-800)',
        'var(--black)',
      ]}}),_c('img',{staticClass:"powered-by",attrs:{"src":require("@/assets/images/poweredby_dark.svg"),"alt":"Powered by Zoox"}})],1):_vm._e(),_c('z-page-transition',{attrs:{"type":_vm.$vuetify.breakpoint.smAndDown ? 'fade' : 'fade-in-right'}},[_c('router-view',[_c('z-loading')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }