<template>
  <z-descrete-select
    :value="currentLang"
    :items="availableLangs"
    @change="onChangeLang"
  >
    <template #option="{ option }">
      <div class="lang-select-container">
        <z-country-flag
          rounded
          class="mr-2"
          :size="18"
          :country="option.flag"
        />
        {{ option.text }}
      </div>
    </template>
    <template #selected>
      <div class="lang-select-container">
        <z-country-flag
          :class="{ 'mr-2': !onlyFlag }"
          rounded
          :size="18"
          :country="currentCountry.flag"
        />
        <span v-if="!onlyFlag">
          {{ currentCountry.text }}
        </span>
      </div>
    </template>
  </z-descrete-select>
</template>

<script>
import find from "lodash.find"
import { computed } from "vue-demi"
import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { LANGS } from "@/consts"
import { useStore } from "@/store/index"

export default {
  props: {
    onlyFlag: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { currentLang } = useNamespacedState("layout", ["currentLang"])
    const { changeLang } = useNamespacedMutations("layout", ["changeLang"])
    const currentCountry = computed(() => {
      return find(LANGS, { value: currentLang.value }) || {}
    })
    const onChangeLang = (value) => {
      changeLang(value)
      const store = useStore()
      store.dispatch("company/getTermsOfUse", value)
      store.dispatch("company/getTermsOfPrivacy", value)
    }

    return {
      currentCountry,
      currentLang,
      availableLangs: LANGS,

      onChangeLang,
    }
  },
}
</script>

<style lang="scss" scoped>
.lang-select-container {
  display: grid;
  place-items: center;
  grid-template-columns: auto 1fr;
  text-transform: capitalize;
  font-weight: 400;
}
</style>
