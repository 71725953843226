const MASTER_GUEST_KEY = "master:from"

/**
 *
 */
export function getMasterGuestFromStorage(reservationId) {
  try {
    const masterGuest = window.localStorage.getItem(
      `${MASTER_GUEST_KEY}:${reservationId}`
    )

    return JSON.parse(masterGuest)
  } catch (e) {
    return null
  }
}

/**
 *
 */
export function saveMasterGuestStorage(reservationId, guest) {
  window.localStorage.setItem(
    `${MASTER_GUEST_KEY}:${reservationId}`,
    JSON.stringify(guest)
  )
}

/**
 *
 */
export function hasMasterGuestData(reservationId) {
  return !!window.localStorage.getItem(`${MASTER_GUEST_KEY}:${reservationId}`)
}
