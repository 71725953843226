import alerts from "./pt/alerts.json"
import documentScanner from "./pt/documentScanner.json"
import error from "./pt/error.json"
import guest from "./pt/guest.json"
import modal from "./pt/modal.json"
import onboarding from "./pt/onboarding.json"
import options from "./pt/options.json"
import parameters from "./pt/parameters.json"
import recognition from "./pt/recognition.json"
import reservation from "./pt/reservation.json"
import validations from "./pt/validations.json"
import warrantyRegister from "./pt/warrantyRegister.json"
import welcome from "./pt/welcome.json"

export default {
  alerts,
  error,
  options,
  guest,
  modal,
  welcome,
  onboarding,
  reservation,
  parameters,
  validations,
  recognition,
  documentScanner,
  warrantyRegister,
}
