import dayjs from "dayjs"
import find from "lodash.find"

/**
 * Responsável por guardar as informações
 * da company que o usuário está utilizando.
 */
import http from "@/http"

const state = {
  name: "",
  companyId: "",
  groupId: "",
  groupOrCompany: "",
  terms: {
    use: {},
    privacy: {},
  },
  hotel: {
    name: "",
    nomeFantasia: "",
    email: "",
    fullAddress: "",
    phoneNumber: "",
    subdomain: "",
    minCheckin: "",
    maxCheckout: "",
  },
  welcome: {},
  stepsConfig: [],
  rules: {},
}

/**
 *
 */
const mutations = {
  setCompany(state, { name, companyId, groupId, welcome, rules }) {
    state.name = name
    state.companyId = companyId
    state.groupId = groupId
    state.welcome = welcome
    state.rules = rules
    document.title = name
    // Sempre utilizaremos uma company, e não um grupo, para o Portal
    const groupOrCompany = JSON.stringify({ type: "company", id: companyId })
    state.groupOrCompany = window.btoa(
      unescape(encodeURIComponent(groupOrCompany))
    )
  },

  setHotel(state, hotel) {
    state.hotel = hotel
  },
  setTermsOfUse(state, term) {
    state.terms.use = term
  },
  setTermsOfPrivacy(state, term) {
    state.terms.privacy = term
  },
  setStepsConfig(state, stepsConfig) {
    state.stepsConfig = stepsConfig

    const personalDataConfig = find(state.stepsConfig, {
      id: "informacao-pessoal",
    })
    const indexOfPersonalDataConfig =
      state.stepsConfig.indexOf(personalDataConfig)

    const onboardingConfig = find(state.stepsConfig, {
      id: "onboarding",
    })
    const indexOfOnboardingConfig = state.stepsConfig.indexOf(onboardingConfig)

    if (onboardingConfig) {
      state.stepsConfig[indexOfOnboardingConfig].content.imageUrl =
        onboardingConfig?.content?.imageUrl
          ? process.env.VUE_APP_ASSETS_URL + onboardingConfig?.content?.imageUrl
          : ""
    }

    if (personalDataConfig?.fields?.length > 0) {
      let personalization =
        state.stepsConfig[indexOfPersonalDataConfig].fields[0]?.documentData
          ?.mainDocument?.personalization

      if (!personalization) {
        if (
          !state.stepsConfig[indexOfPersonalDataConfig].fields[0].documentData
            .mainDocument
        ) {
          state.stepsConfig[
            indexOfPersonalDataConfig
          ].fields[0].documentData.mainDocument = {}
        }

        state.stepsConfig[
          indexOfPersonalDataConfig
        ].fields[0].documentData.mainDocument.personalization = {}

        personalization =
          state.stepsConfig[indexOfPersonalDataConfig].fields[0]?.documentData
            ?.mainDocument?.personalization
      }

      personalization.imageUrl = personalization?.imageUrl
        ? process.env.VUE_APP_ASSETS_URL + personalization?.imageUrl
        : ""

      if (!personalization.instructions) {
        personalization["instructions"] = {}
      }

      personalization.instructions.imageUrl = personalization?.instructions
        ?.imageUrl
        ? process.env.VUE_APP_ASSETS_URL + personalization.instructions.imageUrl
        : ""
    }
  },
}

/**
 *
 */
const actions = {
  async createExternalAccess(_, payload) {
    const expiration = dayjs().add(30, "minutes").toISOString()

    return await http.post("companies/guest-control/external-access", {
      companyId: payload.companyId,
      isAnnonymous: payload.isAnnonymous,
      reservationId: payload.reservationId,
      personId: payload.personId,
      identifier: payload.identifier,
      name: payload.name,
      lastName: payload.lastName,
      roomName: payload.roomName,
      isWalkIn: payload.isWalkIn,
      expiration,
    })
  },
  async redirectToGuestControl(
    { dispatch, rootState },
    { isAnnonymous = false } = {}
  ) {
    const { reservation = {} } = rootState?.reservation ?? {}

    const { data: urlExternal } = await dispatch("createExternalAccess", {
      companyId: state.companyId,
      reservationId: reservation.id,
      isAnnonymous,
      personId: null,
      identifier: null,
      name: null,
      lastName: null,
      roomName: null,
      isWalkIn: true,
    })

    if (urlExternal) {
      window.location.href = `${urlExternal.redirectURL}`
    }
  },

  async getTermsOfUse({ commit }, lang = "pt_br") {
    const resp = await http.get(`terms?type=1&textOnly=1&language=${lang}`)

    commit("setTermsOfUse", resp.data.body)
  },

  async getTermsOfPrivacy({ commit }, lang = "pt_br") {
    const resp = await http.get(`terms?type=2&textOnly=1&language=${lang}`)

    commit("setTermsOfPrivacy", resp.data.body)
  },
}

/**
 *
 */
const getters = {
  requiredSignature(state) {
    const signatureStep = find(state.stepsConfig, { id: "assinatura" })

    return !!signatureStep?.rules?.mandatorySignature
  },
  personalInfoConfig(state) {
    const stepConfig = find(state.stepsConfig, { id: "informacao-pessoal" })

    if (stepConfig && Array.isArray(stepConfig.fields)) {
      return stepConfig.fields[0]
    }

    return {}
  },
  accommodationConfig(state) {
    const stepConfig = find(state.stepsConfig, {
      id: "preferencia-de-acomodacao",
    })

    if (stepConfig && Array.isArray(stepConfig.fields)) {
      return stepConfig
    }

    return {}
  },
  additionalDataConfig(state) {
    const stepConfig = find(state.stepsConfig, { id: "dados-adicionais" })

    if (stepConfig && Array.isArray(stepConfig.fields)) {
      const config = stepConfig.fields[0]
      return {
        ...config,
        additionalData: config.additionalData || {},
      }
    }

    return {}
  },
}

/**
 *
 */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
