<template>
  <div>
    <z-card-modal
      v-if="$vuetify.breakpoint.mdAndUp"
      :title="$t('documentScanner.crop_modal_title')"
      :opened="opened"
      height="auto"
    >
      <template #content>
        <p class="my-2">
          {{ $t("documentScanner.crop_modal_subtitle") }}
        </p>
        <div class="main-content-crop-modal">
          <section class="photo-container">
            <z-photo-upload
              ref="photoUpload"
              dashed
              crop-whole-image
              with-points
              :image="currentImage"
              class="mx-auto"
            />
          </section>
          <section class="d-flex justify-end my-2" style="width: 100%">
            <z-btn
              class="mx-3"
              :text="$t('documentScanner.button_cancel')"
              @click="$emit('close')"
            />
            <z-btn
              :text="$t('documentScanner.crop_modal_button_confirm')"
              primary
              @click="onClickCropImage"
            />
          </section>
        </div>
      </template>
    </z-card-modal>
    <div
      v-else
      class="document-scanner-crop"
      style="
        position: absolute;
        top: 0;
        height: 100vh;
        left: 0;
        bottom: 0;
        right: 0;
      "
    >
      <z-icon
        class="close-icon"
        size="20px"
        color="white"
        @click="$emit('close')"
      >
        $close_rounded
      </z-icon>
      <section class="section-photo">
        <z-photo-upload
          ref="photoUpload"
          class="photo-upload"
          dashed
          crop-whole-image
          with-points
          :image="currentImage"
        />
      </section>

      <div class="document-scan-instructions body3">
        {{ $t("documentScanner.cropper_modal_title") }}
      </div>

      <section class="btn-section">
        <z-btn
          :text="$t('documentScanner.cropper_modal_button_cut')"
          width="100%"
          primary
          class="my-4"
          @click="onClickCropImage"
        />
        <z-btn
          :text="$t('documentScanner.cropper_modal_button_cancel')"
          @click="$emit('close')"
        />
      </section>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from "vue"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    guest: {
      type: Object,
      default: () => {},
    },
    image: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { image } = toRefs(props)
    const currentImage = ref("")
    let tag = Object.keys(image.value)[0]

    if (tag === "front") {
      currentImage.value = image.value.front
    } else {
      currentImage.value = image.value.back
    }

    const imageInput = ref(null)
    const photoUpload = ref(null)

    const onClickCropImage = async () => {
      const croppedImage = photoUpload.value.getCroppedImage()

      emit("confirm:image", croppedImage, tag)
    }

    return {
      imageInput,
      onClickCropImage,
      photoUpload,
      currentImage,
    }
  },
}
</script>

<style lang="scss" scoped>
.main-content-crop-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-upload {
  display: grid;
  align-items: center;
}

.photo-container {
  width: 100%;
  min-height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
.zoox-component .zoox-photo-upload {
  width: 100%;
  height: 100%;
}

.document-scanner-crop {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  height: 100%;

  .close-icon {
    display: block;
    top: 0;
    right: 0;
    z-index: 1;
    align-self: end;
    padding-right: 10px;
    margin: 8px;
  }

  .btn-section {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .section-photo {
    width: 90%;
    min-height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .document-scan-instructions {
    color: var(--white);
    max-width: 40ch;
    text-align: center;
    margin: 10px auto;
    padding: 0 30px;
  }
}
</style>
