import store from "@/store"

export function verifyRedirectCheckout(to, from, next) {
  if (from.name !== null) {
    next()
    return
  }

  if (to.name === "checkout" || to.name === "checkout-reservation") {
    store.dispatch("company/redirectToGuestControl", { isAnnonymous: true })
  } else {
    next()
  }
}
