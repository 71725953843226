<template>
  <z-snackbar
    data-cy="snackbar"
    :right="$vuetify.breakpoint.mdAndUp"
    :color="color"
    :opened="isVisible"
    :confirmation-text="confirmationText || undefined"
    :confirmation="'ok' !== ''"
    :text="message"
    @click="confirmNotification"
  />
</template>

<script>
import {
  useNamespacedActions,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

export default {
  setup() {
    const { isVisible, message, type, confirmationText, color } =
      useNamespacedState("notifications", [
        "isVisible",
        "message",
        "type",
        "confirmationText",
        "color",
      ])

    const { confirmNotification } = useNamespacedActions("notifications", [
      "confirmNotification",
    ])

    return {
      isVisible,
      message,
      type,
      confirmationText,
      color,
      confirmNotification,
    }
  },
}
</script>
