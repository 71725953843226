<template>
  <div class="main-container">
    <z-card style="height: auto" width="100%" class="z-card-height">
      <template #content>
        <div class="reservation-container">
          <p class="body3 font-weight-bold mb-2" style="color: var(--primary)">
            {{ hotel.nomeFantasia }}
          </p>
          <p class="text-uppercase body3 mb-2 ml-3 text-mobile">
            {{ $t("reservation.reservation_number") }}
            <span>
              <b>{{ reservationNumber }}</b>
            </span>
          </p>
          <p class="text-uppercase body3 text-mobile">
            {{ $t("reservation.check_in") }}
            <span>
              <b>{{ parsedCheckin }}</b>
            </span>
          </p>
          <p class="text-uppercase body3 text-mobile text-checkout">
            {{ $t("reservation.check_out") }}
            <span>
              <b>{{ parsedCheckout }}</b>
            </span>
          </p>
        </div>
      </template>
    </z-card>
  </div>
</template>

<script>
import dayjs from "dayjs"
import { computed, toRefs } from "vue"

export default {
  props: {
    hotel: {
      type: Object,
      required: true,
    },
    reservationNumber: {
      type: String,
      default: "",
    },
    checkinDate: {
      type: String,
      default: "",
    },
    checkoutDate: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { checkinDate, checkoutDate } = toRefs(props)
    const parsedCheckin = computed(() =>
      dayjs(checkinDate.value.replace("Z", "")).format("DD/MM/YYYY")
    )
    const parsedCheckout = computed(() =>
      dayjs(checkoutDate.value.replace("Z", "")).format("DD/MM/YYYY")
    )

    return { parsedCheckin, parsedCheckout }
  },
}
</script>

<style>
.main-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 15px;
}
.reservation-container {
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: row;
  place-items: start;
}
p {
  margin-bottom: 0 !important;
}
.alert-container {
  display: grid;
  grid-template-columns: 1fr 3.5fr;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  align-items: center;
}
.alert-container p {
  color: #666666;
}
.text-checkout {
  margin-left: 12px;
}

@media screen and (min-width: 960px) {
  .main-container {
    flex-direction: column;
  }
  .alert-container {
    display: grid;
    grid-template-rows: 1fr;
  }
}

@media screen and (max-width: 375px) {
  .text-mobile {
    display: flex;
    flex-direction: column;
  }
  .reservation-container {
    place-items: center;
  }
  .text-checkout {
    margin-left: 5px;
    justify-self: center;
  }
  .z-card-height {
    height: auto !important;
  }
}
</style>
