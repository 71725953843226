import guest from "./flow/guest"
import reservation from "./flow/reservation"

/**
 * Módudo de gerenciamento de fluxos
 */
export default (router) => ({
  namespaced: true,
  modules: {
    guest: guest(router),
    reservation: reservation(router),
  },
})
