<template>
  <div class="mobile-layout">
    <z-page-transition type="fade">
      <router-view></router-view>
    </z-page-transition>
    <img
      class="powered-by"
      src="@/assets/images/poweredby_dark.svg"
      alt="Powered by Zoox"
    />
  </div>
</template>

<script>
export default {
  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.mobile-layout {
  display: grid;
  gap: 20px;
  justify-items: center;
  align-items: start;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100vw;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: env(safe-area-inset-bottom, 2em);
}

.powered-by {
  align-self: end;
  justify-self: center;
  padding-bottom: 10px;
}
</style>
