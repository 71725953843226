<template>
  <z-card-modal
    :opened="opened"
    persistent
    :title="$t('recognition.saveBiometricModal_title')"
    width="490px"
  >
    <template #content>
      <div class="main-content-modal my-2">
        <p class="body-text text-left my-2">
          {{ $t("recognition.saveBiometricModal_description") }}
        </p>
        <p class="my-3 body-2 font-weight-bold">
          {{ $t("recognition.saveBiometricModal_subtitle") }}
        </p>
        <section class="btn-biometrics">
          <z-btn
            primary
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '230px'"
            class="mx-2"
            data-cy="register-modal-btn"
            :text="$t('recognition.saveBiometricModal_buttonYes')"
            @click="$emit('click:register')"
          />
          <z-btn
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '220px'"
            :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : 'mx-2'"
            data-cy="noRegister-modal-btn"
            :text="$t('recognition.saveBiometricModal_buttonNo')"
            @click="$emit('click:no-register')"
          />
        </section>
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content-modal {
  display: flex;
  flex-direction: column;

  align-items: center;
}
.btn-biometrics {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .btn-biometrics {
    flex-direction: column;
    width: 100%;
  }
}
</style>
