<template>
  <z-card-modal :title="title" width="540px" persistent :opened="opened">
    <template #content>
      <div class="d-flex justify-space-between mb-5">
        <p class="my-2 body3">
          {{ $t("documentScanner.scanner_modal_subtitle") }}
        </p>
        <section class="d-flex justify-space-between">
          <z-chip
            class="mr-2"
            icon="$check_in_a_circle"
            data-cy="frente-chip"
            icon-right
            :text="frontLabel"
            :selected="!!front"
            :color="front ? 'green' : null"
          />
          <z-chip
            data-cy="back-chip"
            icon="$check_in_a_circle"
            icon-right
            :disabled="!front"
            :text="backLabel"
            :color="isScanningBack ? 'grey' : 'grey'"
          />
        </section>
      </div>

      <div
        class="pass-hospitality document-scanner d-flex flex-column"
        :style="isCropping ? 'height:388px' : ''"
      >
        <div class="camera-stream-video">
          <z-camera-stream ref="cameraStream" :stream="stream" />
        </div>
        <img class="shade-image" src="@/assets/images/shade-doc-scanner.svg" />
        <z-photo-upload
          v-if="isCropping"
          ref="photoUpload"
          dashed
          crop-whole-image
          with-points
          :image="currentImage"
          class="crop-picture"
        />
        <img
          v-if="!isCropping"
          class="document-target"
          src="@/assets/images/mira-document-scanner.svg"
          alt="Document target"
        />
      </div>
      <section class="d-flex justify-end mt-4 mb-2">
        <z-btn
          class="document-scan-btn mx-3"
          :text="$t('documentScanner.button_cancel')"
          :disabled="disabled || isLoading"
          @click="$emit('close')"
        />
        <z-btn
          v-if="!isCropping"
          data-cy="take-pic-btn"
          primary
          class="document-scan-btn"
          text="Escanear"
          :disabled="disabled"
          :is-loading="isLoading"
          @click="onScanClick"
        />
        <z-btn
          v-else
          class="document-scan-btn"
          data-cy="btn-crop-image"
          :text="$t('documentScanner.button_confirm')"
          primary
          :is-loading="isLoading"
          @click="onClickCropImage"
        />
      </section>
    </template>
  </z-card-modal>
</template>

<script>
import { computed, ref } from "vue"

import { DocumentSide } from "@/consts"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    front: {
      type: [String, Object],
      default: "",
    },
    frontLabel: {
      type: String,
      required: true,
    },
    back: {
      type: [String, Object],
      default: "",
    },
    backLabel: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    stream: {
      type: MediaStream,
      default: null,
    },
  },

  setup(_, { emit }) {
    const cameraStream = ref(null)
    const currentImage = ref(null)
    const photoUpload = ref(null)
    const isCropping = ref(false)
    const scanSide = ref(DocumentSide.Front)

    const isScanningBack = computed(() => {
      return scanSide.value === DocumentSide.Back
    })

    const onScanClick = async () => {
      const image = await cameraStream.value.getImage()

      currentImage.value = image
      isCropping.value = true
    }

    const onClickCropImage = async () => {
      const croppedImage = photoUpload.value.getCroppedImage()
      emit("change", { image: croppedImage, side: scanSide.value })

      if (scanSide.value === DocumentSide.Front) {
        scanSide.value = DocumentSide.Back
        isCropping.value = false
      } else if (scanSide.value === DocumentSide.Back) {
        isCropping.value = false
        emit("finish")
      }
    }

    return {
      DocumentSide,

      isCropping,
      isScanningBack,
      cameraStream,
      currentImage,
      photoUpload,
      scanSide,

      onScanClick,
      onClickCropImage,
    }
  },
}
</script>

<style lang="scss" scoped>
.pass-hospitality {
  &.document-scanner {
    align-items: center;
    justify-content: center !important;
    height: 345px;
    background-color: #666666;
    position: relative;

    .shade-image {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      object-fit: cover;
      margin: auto;
    }

    .document-target {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      margin: auto;
      width: 90%;
    }

    .crop-picture {
      position: absolute;
      object-fit: cover;
      z-index: 3;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      height: 338px !important;

      > div {
        height: 338px !important;
      }

      .cropper-container {
        height: 100% !important;
      }
    }
  }
}
</style>

<style lang="scss">
.pass-hospitality {
  &.document-scanner {
    video {
      object-fit: cover;
      position: initial !important;
    }
  }
}

.camera-stream-video {
  height: 340px !important;

  .error-text {
    visibility: hidden !important;
  }
}
</style>
