<template>
  <z-card-modal
    persistent
    :opened="opened"
    width="400px"
    height="auto"
    class="card-container-recognition"
  >
    <template #content>
      <section class="main-content-instructions-modal">
        <h5 class="body-text text-center">
          {{ $t("recognition.confirmImageModal_title") }}
        </h5>

        <uploaded-card
          :image-source="'Foto_rosto.jpg'"
          :image="image"
          :enable-close="enableClose"
        />
      </section>
      <div class="btn-container">
        <z-btn
          primary
          width="110px"
          class="mb-3 ml-3"
          data-cy="send-modal-btn"
          :text="$t('recognition.confirmImageModal_buttonSend')"
          @click="$emit('click:send-image')"
        />
        <z-btn
          data-cy="cancel-modal-btn"
          width="110px"
          :text="$t('recognition.confirmImageModal_buttonCancel')"
          @click="$emit('click:cancel')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    imageSource: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    enableClose: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
}
</script>

<style lang="scss" scoped>
img {
  width: 70%;
  margin: 0 auto;
}

.btn-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.main-content-instructions-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
