/**
 * Verifica se o UUID é valido
 *
 * @param {*} str - UUID
 * @returns
 */
export function isValidUUID(str) {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}
