import alerts from "./en/alerts.json"
import documentScanner from "./en/documentScanner.json"
import error from "./en/error.json"
import guest from "./en/guest.json"
import modal from "./en/modal.json"
import onboarding from "./en/onboarding.json"
import options from "./en/options"
import parameters from "./en/parameters.json"
import recognition from "./en/recognition.json"
import reservation from "./en/reservation.json"
import validations from "./en/validations.json"
import warrantyRegister from "./en/warrantyRegister.json"
import welcome from "./en/welcome.json"

export default {
  alerts,
  error,
  guest,
  modal,
  welcome,
  onboarding,
  options,
  reservation,
  parameters,
  validations,
  recognition,
  documentScanner,
  warrantyRegister,
}
