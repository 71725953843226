import dayjs from "dayjs"

import { STATUS_RESERVATION } from "@/consts"
import http from "@/http"
import i18n from "@/i18n"
import { postDocumentWithPresignedURL } from "@/utils/upload"
import { changeChunkDeep } from "@/utils/vuex"

export const SearchTypes = {
  RESERVATION: "reservation",
  NAME: "name",
  DOCUMENT: "document",
  MAIL: "mail",
  PHONE: "phone",
  FACIAL_RECOGNITION: "facial_recognition",
}

/**
 *
 */
const state = {
  availableReservations: [],
  searchTypes: [],
  guestId: null,
  isInvalidOrExpired: false,
  search: {
    type: SearchTypes.RESERVATION,
    params: {
      name: "",
      lastName: "",
      checkinDate: "",
      checkoutDate: "",

      reservationNumber: "",

      documentTypeId: 1,
      documentNumber: "",

      contact: "",

      image: "",
      currentCountry: "BR",
    },
  },
  faceId: {
    currentImage: "",
  },
}

/**
 *
 */
const mutations = {
  resetSearch(state) {
    state.search.params.name = ""
    state.search.params.lastName = ""
    state.search.params.checkinDate = ""
    state.search.params.checkoutDate = ""
    state.search.params.reservationNumber = ""
    state.search.params.documentTypeId = 1
    state.search.params.documentNumber = ""
    state.search.params.contact = ""

    if (state.search.type === SearchTypes.PHONE) {
      state.search.params.contact = "+55"
      state.search.params.currentCountry = "BR"
    }
  },
  changeSearch: changeChunkDeep("search"),
  changeFaceId: changeChunkDeep("faceId"),

  setAvailableReservations(state, reservations) {
    state.availableReservations = reservations
  },

  setIsInvalidOrExpired(state) {
    state.isInvalidOrExpired = state
  },

  setSearchTypes(state, searchTypes) {
    state.searchTypes = searchTypes
  },

  setFaceId(state, currentFaceId) {
    state.faceId = currentFaceId
  },
}

const reservationIsInvalid = (reservation) => {
  const statusReservations = [
    STATUS_RESERVATION.CANCELLED,
    STATUS_RESERVATION.NO_SHOW,
    STATUS_RESERVATION.CHECKOUT,
    STATUS_RESERVATION.PENDING,
  ]

  return statusReservations.includes(reservation.resStatus)
}

const isExpiredReservation = (reservation) => {
  if (reservation?.checkoutDate == "") return false

  const checkoutDate = dayjs(reservation?.checkoutDate)
    .toISOString()
    .split("T")[0]
  const today = dayjs().toISOString().split("T")[0]

  return dayjs(checkoutDate).isBefore(today)
}

const getValidReservations = (reservations) => {
  if (reservations?.length == null) return []

  const valids = reservations.map((res) => {
    if (isExpiredReservation(res) || reservationIsInvalid(res)) return null
    return res
  })

  return valids.filter(Boolean)
}

/**
 *
 */
const actions = {
  async sendFaceImage({ state, commit, rootState }) {
    const { key } = await postDocumentWithPresignedURL({
      path: `${rootState.company.companyId}/recognition`,
      src: state.faceId.currentImage,
    })

    commit("changeSearch", { "params.image": `${key}` })
  },

  onChangeSearchType({ commit }, type) {
    commit("changeSearch", { type })
    commit("resetSearch")
  },

  async searchReservation({ commit, dispatch, state, rootState }) {
    commit("setAvailableReservations", [])
    dispatch("flow/reservation/removeMultiReservationStep", null, {
      root: true,
    })

    let params = {}

    switch (state.search.type) {
      case SearchTypes.DOCUMENT:
        params = {
          documentTypeId: state.search.params.documentTypeId,
          documentNumber: state.search.params.documentNumber,
        }
        break

      case SearchTypes.MAIL:
        params = {
          contact: state.search.params.contact.toLowerCase().trim(),
          lastName: state.search.params.lastName,
        }
        break

      case SearchTypes.PHONE:
        params = {
          lastName: state.search.params.lastName,
          contact: state.search.params.contact.replace(/[+\s|()-]/g, ""),
        }
        break

      case SearchTypes.NAME:
        params = {
          name: state.search.params.name,
          lastName: state.search.params.lastName,
          checkinDate: state.search.params.checkinDate,
          checkoutDate: state.search.params.checkoutDate,
        }
        break

      case SearchTypes.RESERVATION:
        params = {
          reservationNumber: state.search.params.reservationNumber,
          lastName: state.search.params.lastName,
        }
        break

      case SearchTypes.FACIAL_RECOGNITION:
        params = {
          url: state.search.params.image,
        }
        break
    }

    const resp = await http.post("/appointments/search", {
      ...params,
      companyId: rootState.company.companyId,
    })

    const reservations = getValidReservations(resp?.data)

    if (reservations.length > 1) {
      dispatch("flow/reservation/addMultiReservationStep", null, { root: true })
      commit("setAvailableReservations", resp.data)
      dispatch("flow/reservation/next", null, { root: true })
    } else if (reservations.length === 1) {
      const reservation = reservations[0]

      const isExpired = isExpiredReservation(reservation)

      if (isExpired === false) {
        commit("reservation/changeReservation", reservation, { root: true })
        dispatch("flow/reservation/next", null, { root: true })
      }
    } else {
      commit("setIsInvalidOrExpired", true)
    }
  },

  async searchReservationByGuestId({ commit }, guestId) {
    const resp = await http.get(`/clients/${guestId}`)

    commit(
      "reservation/changeReservation",
      { ...resp.data, id: resp.data._id },
      { root: true }
    )
  },

  async searchReservationById({ commit }, reservationId) {
    const resp = await http.get(`/appointments/${reservationId}`)

    commit(
      "reservation/changeReservation",
      { ...resp.data, id: resp.data._id },
      { root: true }
    )
  },

  selectReservation({ dispatch, commit }, reservation) {
    const isExpired = isExpiredReservation(reservation)

    if (isExpired === false) {
      commit("reservation/changeReservation", reservation, { root: true })
      dispatch("flow/reservation/next", null, { root: true })
    }
  },
}

/**
 *
 */
const getters = {
  searchMethods(state) {
    return state.searchTypes
      .map((searchType) => {
        if (searchType?.enableSearchByReservationNumber) {
          return {
            text: i18n.tc("welcome.reservation_number"),
            value: SearchTypes.RESERVATION,
          }
        }

        if (searchType?.enableSearchByDocument) {
          return {
            text: i18n.tc("welcome.document"),
            value: SearchTypes.DOCUMENT,
          }
        }

        if (searchType?.enableSearchByEmailOrPhoneNumber) {
          return [
            {
              text: i18n.tc("welcome.email"),
              value: SearchTypes.MAIL,
            },
            {
              text: i18n.tc("welcome.phone_number"),
              value: SearchTypes.PHONE,
            },
          ]
        }
        if (searchType?.enableSearchByName) {
          return {
            text: i18n.tc("welcome.name"),
            value: SearchTypes.NAME,
          }
        }

        if (searchType?.enableSearchByFacialRecognition) {
          return {
            text: i18n.tc("welcome.facial_recognition"),
            value: SearchTypes.FACIAL_RECOGNITION,
          }
        }
        return null
      })
      .flat()
      .filter((type) => type !== null)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
