<template>
  <z-card-modal
    persistent
    :opened="opened"
    width="440px"
    height="auto"
    class="card-container-recognition"
    closable
    @change="$emit('close')"
  >
    <template #content>
      <section class="main-content-choose-modal">
        <div class="d-flex align-self-center my-4">
          <illustration-facial-recognition></illustration-facial-recognition>
        </div>

        <h4 class="body-text text-center">
          {{ $t("recognition.takeOrUploadModal_title") }}
        </h4>

        <p class="body2 text-justify my-5 px-2">
          {{ $t("recognition.takeOrUploadModal_description") }}
        </p>
      </section>
      <div class="btn-container-take-modal">
        <div>
          <z-btn
            primary
            class="mb-3 mx-2"
            data-cy="upload-modal-btn"
            :text="$t('recognition.takeOrUploadModal_buttonUpload')"
            @click="onSelectImage"
          />
          <input
            id="imageInput"
            ref="imageInput"
            type="file"
            style="display: none"
          />
        </div>
        <z-btn
          data-cy="take-picture-btn"
          :text="$t('recognition.takeOrUploadModal_buttonTake')"
          @click="$emit('click:take-picture')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
import { ref } from "vue"

import { readFile } from "@/composables/useParser"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const imageInput = ref(null)

    const onSelectImage = () => {
      imageInput.value.click()

      imageInput.value.onchange = async (e) => {
        let file = e.target.files[0]
        if (!file) return

        const image = await readFile(file)

        emit("change:photo", {
          photoId: file.name,
          photoUrl: image,
        })
      }
    }
    return {
      onSelectImage,
      imageInput,
    }
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 70%;
  margin: 0 auto;
}

.btn-container-take-modal {
  display: flex;
  margin-top: 0;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.main-content-choose-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
