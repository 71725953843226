import Vue from "vue"
import VueI18n from "vue-i18n"

import en from "./locales/en"
import es from "./locales/es"
import pt from "./locales/pt"

Vue.use(VueI18n)

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "pt",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "pt",
  messages: { pt, en, es },
  silentTranslationWarn: true,
})
