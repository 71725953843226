import "vuetify/dist/vuetify.min.css"
import "@zoox-ui/components/dist/zoox-design-system.css"

import ZooxComponents, { Theme, ZooxIcons } from "@zoox-ui/components"
import Vue from "vue"
import Vuetify from "vuetify"
import en from "vuetify/lib/locale/en"
import es from "vuetify/lib/locale/es"
import pt from "vuetify/lib/locale/pt"

Vue.use(Vuetify)
Vue.use(ZooxComponents)

export const vuetify = new Vuetify({
  theme: {
    light: true,
    ...Theme,
  },
  icons: {
    values: ZooxIcons,
  },
  lang: {
    locales: { pt, en, es },
    current: "pt",
  },
})
