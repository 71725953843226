<template>
  <v-app :class="{ 'app-loading': $route.name === null }">
    <div v-if="$route.name === null">
      <z-loading
        class="loading"
        :colors="[
          'var(--black-050)',
          'var(--black-100)',
          'var(--black-100)',
          'var(--black-300)',
          'var(--black-300)',
          'var(--black-800)',
          'var(--black-800)',
          'var(--black)',
        ]"
      />
      <img
        class="powered-by"
        src="@/assets/images/poweredby_dark.svg"
        alt="Powered by Zoox"
      />
    </div>
    <z-page-transition
      :type="$vuetify.breakpoint.smAndDown ? 'fade' : 'fade-in-right'"
    >
      <router-view>
        <z-loading></z-loading>
      </router-view>
    </z-page-transition>
  </v-app>
</template>

<script>
import { useNetwork } from "@vueuse/core"
import { watch } from "vue"
import { useNamespacedActions } from "vuex-composition-helpers/dist"

import { useZoox } from "@/composables"
import i18n from "@/i18n"

export default {
  name: "App",
  setup() {
    const { isOnline, effectiveType } = useNetwork()
    const { sendNotification } = useNamespacedActions("notifications", [
      "sendNotification",
    ])

    const zoox = useZoox()

    watch(isOnline, (value) => {
      if (!value) {
        sendNotification({
          message: i18n.tc("error.you_are_offline_warning"),
          duration: 60 * 1000 * 1000,
          confirmationText: "Ok",
          color: zoox.colors.orange,
        })
      }
    })

    watch(effectiveType, (value) => {
      if (value === "2g") {
        sendNotification({
          message: i18n.tc("error.you_are_offline_warning"),
          duration: 60 * 1000 * 1000,
          confirmationText: "Ok",
          color: zoox.colors.orange,
        })
      }
    })

    return {}
  },
}
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  width: 200px;
  height: 200px;
  top: calc(50% - 150px);
  left: 50%;
  margin-left: -100px; /* Negative half of width. */
}

.powered-by {
  position: fixed;
  bottom: 50px;
  left: 50%;
  width: 150px;
  margin-left: -75px; /* Negative half of width. */
}

.app-loading {
  background: var(--background) !important;
}
</style>
