import { changeRadius, changeTheme } from "@zoox-ui/components"

import i18n from "@/i18n"
import { vuetify } from "@/plugins/designSystem"

const LANG_STORE_KEY = "pass:lang"

/**
 * API Global para fazer alterações de layout
 */
const state = {
  currentLang: "pt",
  availableLangs: [],
  theme: {
    backgroundUrl: "",
    color: {
      primary: "",
    },
    card: {
      shadow: false,
      radius: "",
    },
    input: {
      radius: "",
    },
    button: {
      radius: "",
      small: {
        radius: "",
      },
    },
    chip: {
      radius: "",
    },
    logo: {
      internal: "",
      external: "",
    },
  },
}

/**
 *
 */
const mutations = {
  changeLang(state, lang) {
    state.currentLang = lang
    i18n.locale = lang
    vuetify.framework.lang.current = lang
    localStorage.setItem(LANG_STORE_KEY, lang)
  },
  setLanguages(state, availableLangs) {
    state.availableLangs = availableLangs
  },
  setTheme(state, theme) {
    state.theme = theme
    state.theme.backgroundUrl = theme.background
      ? process.env.VUE_APP_ASSETS_URL + theme.background
      : ""

    if (!state.theme.logo) {
      state.theme.logo = {}
    }
    state.theme.logo.internal = theme.logo?.internal
      ? process.env.VUE_APP_ASSETS_URL + theme.logo.internal
      : ""

    state.theme.logo.external = theme.logo?.external
      ? process.env.VUE_APP_ASSETS_URL + theme.logo.external
      : ""

    changeTheme(
      { vuetify: vuetify.framework },
      {
        primary: state.theme.color.primary,
        cardShadow: state.theme.card.shadow,
      }
    )
    changeRadius({
      cardRadius: state.theme.card.radius,
      btnRadius: state.theme.button.radius,
      smallBtnRadius: state.theme.button.small.radius,
      chipRadius: state.theme.chip.radius,
      inputRadius: state.theme.input.radius,
    })
  },
}

/**
 *
 */
const actions = {
  setup({ commit }, { defaultLang = "pt", theme } = {}) {
    let lang = localStorage.getItem(LANG_STORE_KEY) || defaultLang

    if (/^pt/.test(lang)) {
      lang = "pt"
    } else if (/^en/.test(lang)) {
      lang = "en"
    } else if (/^es/.test(lang)) {
      lang = "es"
    } else {
      lang = "pt"
    }

    commit("changeLang", lang)
    commit("setTheme", theme)
  },
}

/**
 *
 */
const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
