<template>
  <header class="smck-header">
    <img class="logo" :src="logo" alt="Logo" />
    <div>
      <my-reservation
        v-if="reservation.id"
        :hotel="hotel"
        :reservation="reservation"
        @click="$emit('click:my-reservation')"
      />
      <z-icon
        v-if="showInfoIcon"
        data-cy="info-icon"
        class="info-icon"
        @click="$emit('click:info')"
      >
        $info_question_mark
      </z-icon>
      <language-select :only-flag="$vuetify.breakpoint.smAndDown" />
    </div>
  </header>
</template>

<script>
export default {
  props: {
    logo: {
      type: String,
      default: require("@/assets/images/empty-logo.svg"),
    },
    hotel: {
      type: Object,
      required: true,
    },
    reservation: {
      type: Object,
      default: () => {},
    },
    showInfoIcon: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.logo {
  max-height: 40px;
  max-width: 30%;
}

.smck-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;

  .body3 {
    text-transform: capitalize;
  }

  .info-icon {
    margin: 0 10px;
  }
}

@media screen and (min-width: 960px) {
  .smck-header {
    margin-bottom: 0px;
  }

  .info-icon {
    margin: 0 30px;
  }
}
</style>
