<template>
  <!-- Desktop -->
  <div v-if="$vuetify.breakpoint.mdAndUp" class="pass-alert">
    <z-icon class="pass-alert-icon" size="32px" color="var(--orange)">
      $error_rounded
    </z-icon>
    <div class="card-title pass-alert-text">
      {{ text }}
      <a v-if="clickable" class="pass-alert-link" @click="$emit('click')">
        {{ description }}
      </a>
      <div v-else class="body3 pass-alert-link" @click="$emit('click')">
        {{ description }}
      </div>
    </div>
  </div>

  <!-- Mobile -->
  <div
    v-else
    v-ripple="clickable"
    class="mobile pass-alert"
    @click="$emit('click')"
  >
    <z-icon class="pass-alert-icon" size="32px" color="var(--orange)">
      $error_rounded
    </z-icon>
    <div class="card-title pass-alert-text">
      {{ text }}
    </div>
    <div
      v-if="$vuetify.breakpoint.smAndDown"
      class="body3 pass-alert-link"
      @click="$emit('click')"
    >
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.pass-alert {
  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 15px;
  row-gap: 5px;
  align-items: center;
  padding: 15px;
  border: 2px solid var(--orange);
  border-radius: var(--zoox-card-radius);

  &.mobile {
    .pass-alert-icon {
      grid-row: span 2;
    }
  }

  .pass-alert-text,
  .pass-alert-link {
    color: var(--black-600);
    line-height: 1.2;

    & a {
      text-decoration: underline;
    }
  }

  .pass-alert-link {
    text-transform: initial !important;
  }
}
</style>
