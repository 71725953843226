<template>
  <div class="reservation-layout">
    <reservation-sidebar
      class="sidebar"
      :current-step="currentStep"
      :steps="steps"
      @click:back="$router.push({ name: 'home' })"
    />
    <router-view class="content" />

    <signature-pad-modal
      v-if="$vuetify.breakpoint.mdAndUp && currentStep.route === 'signature'"
      :opened="true"
    />
  </div>
</template>

<script setup>
import { useNamespacedState } from "vuex-composition-helpers/dist"

import SignaturePadModal from "@/views/Reservation/Signature/containers/SignaturePadModal.vue"

const { currentStep, steps } = useNamespacedState("flow/guest", [
  "steps",
  "currentStep",
])
</script>

<style lang="scss" scoped>
//https://every-layout.dev/layouts/sidebar/
.reservation-layout {
  display: flex;
  gap: 15px;
  padding: 15px;
  flex-wrap: wrap;
  align-items: flex-start;

  .sidebar {
    flex-basis: 250px;
    flex-grow: 1;
  }

  .content {
    flex-basis: 0;
    flex-grow: 999;
    min-inline-size: 70%;
  }
}
</style>
