import Alert from "./Alert/Alert.vue"
import HasChildrenModal from "./Alert/AlertTypes/HasChildrenModal.vue"
import AnimatedLoading from "./AnimatedLoading/AnimatedLoading.vue"
import CropModal from "./CropModal/CropModal.vue"
import DocumentScanner from "./DocumentScanner/DocumentScanner.vue"
import ErrorNotificationModal from "./ErrorNotificationModal/ErrorNotificationModal.vue"
import FnrhInfoModal from "./FnrhInfoModal/FnrhInfoModal.vue"
import GuestAvatar from "./GuestAvatar/GuestAvatar.vue"
import GuestCard from "./GuestCard/GuestCard.vue"
import Illustration404 from "./Illustrations/illustration-404.vue"
import IllustrationCertidao from "./Illustrations/illustration-certidao-image.vue"
import IllustrationChecklist from "./Illustrations/illustration-checklist.vue"
import IllustrationDocumentScan from "./Illustrations/illustration-document-scan.vue"
import IllustrationFacialRecognition from "./Illustrations/illustration-facial-recognition.vue"
import IllustrationOnboarding from "./Illustrations/illustration-onboarding.vue"
import IllustrationWelcome from "./Illustrations/illustration-welcome.vue"
import LanguageSelect from "./LanguageSelect.vue"
import MainHeader from "./MainHeader/MainHeader.vue"
import MyReservation from "./MyReservation/MyReservation.vue"
import Notification from "./Notification.vue"
import Recaptcha from "./Recaptcha/Recaptcha.vue"
import ReservationInfos from "./ReservationInfos/ReservationInfos.vue"
import ReservationSidebar from "./ReservationSidebar/ReservationSidebar.vue"
import ShareWithGuestsButton from "./ShareWithGuestsButton/ShareWithGuestsButton.vue"
import ConfirmImageModal from "./TakePicture/components/ConfirmImageModal.vue"
import DocumentCamera from "./TakePicture/components/DocumentCamera/DocumentCamera.vue"
import SaveBiometricsModal from "./TakePicture/components/SaveBiometricsModal.vue"
import TakeOrUploadPictureModal from "./TakePicture/components/TakeOrUploadPictureModal.vue"
import TakePictureModal from "./TakePicture/components/TakePictureModal.vue"
import UploadedCard from "./UploadedCard/UploadedCard.vue"

export default function (Vue) {
  // Generic
  Vue.component("GuestCard", GuestCard)
  Vue.component("Recaptcha", Recaptcha)
  Vue.component("AnimatedLoading", AnimatedLoading)
  Vue.component("PassAlert", Alert)
  Vue.component("ReservationInfos", ReservationInfos)
  Vue.component("HasChildrenModal", HasChildrenModal)
  Vue.component("ShareWithGuestsBtn", ShareWithGuestsButton)
  Vue.component("ErrorNotificationModal", ErrorNotificationModal)
  Vue.component("GuestAvatar", GuestAvatar)
  Vue.component("LanguageSelect", LanguageSelect)
  Vue.component("TakeOrUploadPictureModal", TakeOrUploadPictureModal)
  Vue.component("TakePictureModal", TakePictureModal)
  Vue.component("ConfirmImageModal", ConfirmImageModal)
  Vue.component("UploadedCard", UploadedCard)
  Vue.component("SaveBiometricsModal", SaveBiometricsModal)
  Vue.component("FnrhInfoModal", FnrhInfoModal)

  // Documents
  Vue.component("CropModal", CropModal)
  Vue.component("DocumentCamera", DocumentCamera)
  Vue.component("DocumentScanner", DocumentScanner)

  // Layout
  Vue.component("MainHeader", MainHeader)
  Vue.component("MyReservation", MyReservation)
  Vue.component("Notification", Notification)
  Vue.component("ReservationSidebar", ReservationSidebar)

  //Illustrations
  Vue.component("Illustration404", Illustration404)
  Vue.component("IllustrationCertidao", IllustrationCertidao)
  Vue.component("IllustrationChecklist", IllustrationChecklist)
  Vue.component("IllustrationDocumentScan", IllustrationDocumentScan)
  Vue.component("IllustrationFacialRecognition", IllustrationFacialRecognition)
  Vue.component("IllustrationOnboarding", IllustrationOnboarding)
  Vue.component("IllustrationWelcome", IllustrationWelcome)
}
