<template>
  <z-card-modal
    :title="$t('reservation.finalized_fnrh_modal_title')"
    :width="$vuetify.breakpoint.smAndDown ? '350' : '487'"
    :opened="opened"
    persistent
  >
    <template #content>
      <div class="d-flex flex-column align-center mt-4">
        <z-icon color="var(--lightgreen-600)" size="80">
          $check_in_a_circle
        </z-icon>
        <p class="text-center body-text my-6">
          {{ $t("reservation.finalized_fnrh_modal_description") }}
        </p>
      </div>
      <div class="d-flex justify-end">
        <z-btn
          :text="$t('reservation.ok')"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
          primary
          :is-loading="isLoading"
          @click="$emit('click:ok')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  model: {
    prop: "opened",
    event: "change",
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
