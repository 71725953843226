import get from "lodash.get"
import set from "lodash.set"

/**
 * Cria uma mutação que altera o objeto
 * de maneira recursiva.
 */
export function changeChunkDeep(objectKey) {
  return function (state, chunk = {}) {
    const fields = Object.keys(chunk)

    for (let fieldKey of fields) {
      const existsField = typeof get(state[objectKey], fieldKey) !== "undefined"

      if (existsField) {
        set(state[objectKey], fieldKey, chunk[fieldKey])
      }
    }
  }
}
