import DefaultLayout from "@/layouts/DefaultLayout.vue"
import MobileLayout from "@/layouts/MobileLayout"
import ReservationLayout from "@/layouts/ReservationLayout.vue"
import WelcomeLayout from "@/layouts/WelcomeLayout"

export default [
  {
    name: "checkout",
    path: "/checkout",
  },
  {
    name: "checkout-reservation",
    path: "/checkout/:reservationNumber",
  },
  {
    path: "/welcome",
    component: MobileLayout,
    children: [
      {
        path: "facial-recognition-instructions",
        name: "facial-recognition-instructions",
        props: { strategy: "facialRecognition" },
        component: () =>
          import(
            "@/components/FacialRecognitionInstructions/FacialRecognitionInstructions.vue"
          ),
      },
      {
        path: "take-picture-instructions",
        name: "take-picture-instructions",
        props: { strategy: "takePicture" },
        component: () =>
          import(
            "@/components/FacialRecognitionInstructions/FacialRecognitionInstructions.vue"
          ),
      },
      {
        path: "face-recognition",
        name: "face-recognition",
        props: { strategy: "facialRecognition" },
        component: () => import("@/components/TakePicture/TakePicture"),
      },
      {
        path: "take-picture",
        name: "take-picture",
        props: { strategy: "takePicture" },
        component: () => import("@/components/TakePicture/TakePicture"),
      },
      {
        path: "onboarding",
        name: "onboarding",
        component: () => import("@/views/Welcome/Onboarding.vue"),
      },
    ],
  },
  {
    path: "/welcome",
    component: WelcomeLayout,
    children: [
      {
        path: "",
        name: "welcome",
        component: () => import("@/views/Welcome/Welcome.vue"),
      },
      {
        path: "/validate/pin/:hash",
        name: "validate-hash",
      },
      {
        path: "menu",
        name: "welcome-menu",
        component: () => import("@/views/Welcome/WelcomeMenu"),
      },
      {
        path: "find-your-reservation",
        name: "find-your-reservation",
        component: () => import("@/views/Welcome/FindYourReservation"),
      },
      {
        path: "multi-reservation",
        name: "multi-reservation",
        component: () => import("@/views/Welcome/MultiReservation"),
      },
      {
        path: "reservation/finish",
        name: "reservation-finish",
        component: () => import("@/views/Finish/ReservationInfo.vue"),
      },
    ],
  },
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/document-scan/:reservationId/:guestId",
        name: "document-scan",
        component: () => import("@/views/ResendDocs"),
      },
      {
        path: "/document-scan/:reservationId/:guestId/upload",
        name: "document-scan-upload",
        component: () => import("@/views/ResendDocs/DocumentUpload"),
      },
      {
        path: "/document-scan/:reservationId/:guestId/success",
        name: "document-scan-success",
        component: () => import("@/views/ResendDocs/ResendSuccess"),
      },
      {
        path: "home",
        name: "home",
        component: () => import("@/views/Home/index.vue"),
      },

      {
        name: "data-validation",
        path: "data-validation",
        component: () => import("@/views/Auth/DataValidation.vue"),
      },
      {
        name: "token-validation",
        path: "token-validation",
        component: () => import("@/views/Auth/TokenValidation.vue"),
      },
      {
        name: "person-data-validation",
        path: "person-data-validation",
        component: () => import("@/views/Auth/PersonValidation.vue"),
      },
      {
        name: "person-token-validation",
        path: "person-token-validation",
        component: () => import("@/views/Auth/PersonTokenValidation.vue"),
      },
      {
        name: "notification",
        path: "home/notification",
        component: () => import("@/views/Home/GuestNotification"),
      },
      {
        path: "/reservation",
        component: ReservationLayout,
        children: [
          {
            name: "personal-info",
            path: "personal-info",
            component: () => import("@/views/Reservation/PersonalInfo"),
          },
          {
            name: "additional-data",
            path: "additional-data",
            component: () => import("@/views/Reservation/AdditionalData"),
          },
          {
            name: "confirmation",
            path: "confirmation",
            component: () => import("@/views/Reservation/Confirmation"),
          },
          {
            name: "accommodation",
            path: "accommodation",
            component: () =>
              import("@/views/Reservation/Confirmation/Accommodation/"),
          },

          {
            name: "warranty",
            path: "warranty",
            component: () =>
              import("@/views/Reservation/Warranty/Warranty.vue"),
            children: [
              {
                name: "unregistered-payment",
                path: "unregistered-payment",
                component: () =>
                  import(
                    "@/views/Reservation/Warranty/UnregisteredPayment.vue"
                  ),
              },

              {
                name: "wallet-payment",
                path: "wallet-payment",
                component: () =>
                  import("@/views/Reservation/Warranty/PaymentWithWallet.vue"),
              },

              {
                name: "credit-card-registration",
                path: "credit-card-registration",
                component: () =>
                  import(
                    "@/views/Reservation/Warranty/CreditCardRegistration.vue"
                  ),
              },

              {
                name: "processing-payment",
                path: "processing-payment",
                component: () =>
                  import("@/views/Reservation/Warranty/ProcessingPayment.vue"),
              },
            ],
          },

          {
            name: "forgot-address",
            path: "/reservation/forgot-address",
            component: () =>
              import(
                "@/views/Reservation/PersonalInfo/components/ForgotAdress.vue"
              ),
          },
          {
            name: "forgot-address-results",
            path: "/reservation/forgot-address-results",
            component: () =>
              import(
                "@/views/Reservation/PersonalInfo/components/ForgotAddressResults.vue"
              ),
          },
        ],
      },
      {
        name: "signature",
        path: "/reservation/signature",
        component: () =>
          import("@/views/Reservation/Signature/SignaturePad.vue"),
      },
      {
        name: "404",
        path: "/error/not-found",
        component: () => import("@/views/404.vue"),
      },
      {
        name: "500",
        path: "/error/server-error",
        component: () => import("@/views/500.vue"),
      },
      {
        name: "direct-entry",
        path: "/:reservationId/:guestId",
      },
    ],
  },
]
