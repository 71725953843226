const state = {
  isVisible: false,
  timeout: null,
  message: "",
  confirmationText: "",
  type: "default",
  color: "",
  onConfirm: () => {},
}

const mutations = {
  sendNotification(
    state,
    { message, duration, type, confirmationText, onConfirm, color } = {}
  ) {
    state.message = message
    state.type = type
    state.isVisible = true
    state.confirmationText = confirmationText
    state.onConfirm = onConfirm || state.onConfirm
    state.color = color

    state.timeout = setTimeout(() => {
      state.isVisible = false
    }, duration)
  },

  confirmNotification(state) {
    state.onConfirm()
  },

  clearNotification(state) {
    state.isVisible = false
    state.message = ""
    state.confirmationText = ""
    state.type = "default"
    state.onConfirm = () => {}
    state.color = ""
    clearTimeout(state.timeout)
  },
}

const actions = {
  sendNotification(
    { commit },
    { message, duration, type, confirmationText, onConfirm, color }
  ) {
    commit("clearNotification")
    commit("sendNotification", {
      message,
      duration,
      type,
      confirmationText,
      onConfirm,
      color,
    })
  },
  confirmNotification({ commit }) {
    commit("confirmNotification")
    commit("clearNotification")
  },
}

export default () => ({
  namespaced: true,
  state,
  mutations,
  actions,
})
