import imageCompression from "browser-image-compression"

import http from "@/http"

export async function fromDataUriToBlob(dataURI) {
  const data = await fetch(dataURI)
  const blobData = await data.blob()

  return blobData
}

/***
 * Faz upload de um documento utilizando uma signed URL
 * do S3 usando a api de storage/url
 */
export async function postDocumentWithPresignedURL({
  path,
  src,
  ext,
  metadata = {},
} = {}) {
  const blobData = await fromDataUriToBlob(src)

  let compressed = null

  try {
    const options = { maxWidthOrHeight: 1280, fileType: "image/jpeg" }
    compressed = await imageCompression(blobData, options)
  } catch (err) {
    console.log(err)
  }

  if (ext) {
    const matches = ext.match(/\.(?<ext>[a-zA-Z]+)$/)
    if (matches?.groups?.ext) {
      ext = matches?.groups?.ext
    }
  } else {
    ext = blobData.type.split("/").pop()
  }

  const params = {
    path,
    metadata,
    ext,
  }
  const response = await http.get("/storage", { params })
  const { url, fields } = response.data
  const formData = new FormData()

  for (let key in fields) {
    formData.append(key, fields[key])
  }

  formData.append("file", compressed ?? blobData)

  await fetch(url, {
    method: "POST",
    mode: "no-cors",
    body: formData,
  })

  return {
    url: url,
    key: fields.key,
  }
}
