<template>
  <z-card-modal :opened="opened" persistent width="463px">
    <template #content>
      <div class="checklist-img">
        <illustration-checklist></illustration-checklist>
      </div>
      <p
        data-cy="about-page-modal-description"
        class="body-text text-center"
        v-html="bodyText"
      ></p>
      <div class="modal-btn-container">
        <z-btn
          data-cy="about-page-modal-btn"
          class="modal-btn"
          primary
          width="100%"
          :text="$t('modal.button_ok')"
          @click="$emit('click:close')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    bodyText: {
      type: String,
      default: "",
    },
  },
}
</script>

<style lang="scss" scoped>
.checklist-img {
  text-align: center;
}

.modal-btn {
  margin-top: 20px;
}

@media screen and (min-width: 786px) {
  .modal-btn-container {
    text-align: end;
  }

  .modal-btn {
    width: 155px !important;
  }
}
</style>
