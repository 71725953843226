import { ref } from "vue"
import { useNamespacedActions } from "vuex-composition-helpers/dist"

/**
 * Composable que controla o loading/error de um requisição
 * que está sendo feita de maneira assíncrona
 *
 * @param {*} httpRequest - Método da requisição
 * @returns
 */
export function useAsyncAction(httpRequest) {
  const isLoading = ref(false)
  const hasError = ref(false)
  const statusCode = ref("")

  const reset = () => {
    isLoading.value = false
    hasError.value = false
    statusCode.value = ""
  }

  const makeRequest = async (...params) => {
    try {
      reset()
      isLoading.value = true
      await httpRequest(...params)
    } catch (e) {
      console.log(e)
      hasError.value = true
      statusCode.value = e.response?.status
    } finally {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    hasError,
    makeRequest,
    reset,
    statusCode,
  }
}

/**
 * Composable que controla o loading/error de uma ação do store
 */
export function useStoreAction(module, action) {
  const { [action]: httpRequest } = useNamespacedActions(module, [action])
  const { makeRequest, ...controls } = useAsyncAction(httpRequest)

  return {
    ...controls,
    [action]: makeRequest,
  }
}
