<template>
  <div class="btn-container">
    <z-btn
      v-click-outside="onClickOutside"
      data-cy="send-notification"
      primary
      class="share-btn"
      icon="$share_rounded"
      :style="hover ? { width: '282px', borderRadius: '100px' } : ''"
      :text="hover ? buttonText : ''"
      @click="onClick"
    ></z-btn>
  </div>
</template>

<script>
import { ref } from "vue"

export default {
  props: {
    buttonText: {
      type: String,
      default: "",
    },
  },
  setup(_, { emit }) {
    const hover = ref(false)

    const onClick = () => {
      if (hover.value) {
        emit("click:share")
      }
      hover.value = true
    }

    const onClickOutside = () => {
      hover.value = false
    }

    return {
      hover,
      onClickOutside,
      onClick,
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  justify-content: flex-end;
}

.share-btn {
  align-self: flex-end;
  border-radius: 50%;
  bottom: 0;
  height: 54px !important;
  margin-bottom: 15px;
  margin-right: 15px;
  transition: 300ms;
  position: fixed;
  width: 54px;
}
</style>
