import dayjs from "dayjs"
import filter from "lodash.filter"
import map from "lodash.map"

import { STATUS_RESERVATION } from "@/consts"
import http from "@/http"
import { formatMobile } from "@/utils/helpers"
import { changeChunkDeep } from "@/utils/vuex"

/**
 * Responsável por guardar informações
 * relativas a reserva acessada pelo usuário.
 */
const state = {
  guestsForNotificate: [],
  reservation: {
    id: "",
    adultsNumber: 0,
    childrenNumber: 0,
    checkinDate: "",
    checkoutDate: "",
    isDemonstration: false,
    currentImage: "",
    reservationNumber: "",
    companyId: "",
    resStatus: 0,
    paymentStatus: 0,
    billed: false,
    guests: [],
  },
}

/**
 *
 */
const mutations = {
  changeReservation: changeChunkDeep("reservation"),
  changeGuestsForNotificate: changeChunkDeep("guestsForNotificate"),
  setGuestsForNotificate(state, guests) {
    state.guestsForNotificate = guests
  },
}

/**
 *
 */
const actions = {
  /**
   * Carrega os guests que devem ser notificados
   */
  loadGuestsToNotificate({ commit, getters }) {
    const guests = map(getters.incompletedGuests, (guest) => ({
      id: guest.id,
      name: guest.name,
      fullName: guest.fullName,
      currentCountry: "BR",
      mobile: "55",
      email: "",
      masterGuest: guest.masterGuest,
    })).filter((guest) => guest.masterGuest === false)

    commit("setGuestsForNotificate", guests)
  },

  /**
   * Faz o envio de notificação para os guests
   */
  async sendNotificationToGuest({ state }) {
    const resp = await http.post(`/notify/${state.reservation.id}`, {
      clients: map(state.guestsForNotificate, (guest) => ({
        id: guest.id,
        name: guest.name,
        mobile:
          guest.mobile.length > 3 ? formatMobile(guest.mobile) : undefined,
        email: guest.email || undefined,
      })),
      type: "preCheckin",
    })

    return resp
  },

  /**
   * Envia para o admin as preferências de acomodação
   * da reserva (escolhida pelo hóspede principal)
   */
  async sendAccommodationPreferences({ state }, preferences) {
    const guest = state.reservation.guests.find((guest) => guest.masterGuest)

    const body = {
      companyId: state.reservation.companyId,
      guestId: guest.id,
      preferences,
    }

    await http.put(`/appointments/preferences/set`, body)
  },

  /**
   * Recarrega as informações da reserva
   */
  async reloadReservation({ state, commit }) {
    const guest = state.reservation.guests[0]
    const resp = await http.get(`/clients/${guest.id}`)

    commit("changeReservation", resp.data)
  },
}

/**
 *
 */
const getters = {
  completedGuests(state) {
    return filter(state.reservation.guests, (guest) => !!guest.fnrhId)
  },
  incompletedGuests(state) {
    return filter(state.reservation.guests, (guest) => !guest.fnrhId)
  },
  alerts(state) {
    return {
      hasChildren: state.reservation.childrenNumber > 0,
      isIncompleted: state.reservation.guests.some(
        (guest) => !guest.fnrhId && !guest.masterGuest
      ),
    }
  },
  /**
   * Verifica se o status da reserva está entre
   * [cancelada, no show, checkout, pendente]
   */
  reservationIsInvalid(state) {
    const statusReservations = [
      STATUS_RESERVATION.CANCELLED,
      STATUS_RESERVATION.NO_SHOW,
      STATUS_RESERVATION.CHECKOUT,
      STATUS_RESERVATION.PENDING,
      // STATUS_RESERVATION.TO_CONFIRM,
    ]

    return statusReservations.includes(state.reservation.resStatus)
  },

  /**
   * Verifica se a reserva está expirada
   * comparando a data de checkout com a data
   * de hoje
   */
  reservationIsExpired(state) {
    if (state?.reservation?.checkoutDate == "") return false

    const checkoutDate = dayjs(state?.reservation?.checkoutDate)
      .toISOString()
      .split("T")[0]
    const today = dayjs().toISOString().split("T")[0]

    return dayjs(checkoutDate).isBefore(today)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
