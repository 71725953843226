import axios from "axios"

import store from "./store"

const LangValues = {
  pt: "pt-BR",
  en: "en-US",
  es: "es-ES",
}

/**
 * Instância padrão para fazer chamadas HTTP
 */
const http = axios.create({
  baseURL: process.env.VUE_APP_PROXY_HOST,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-App-Name": "smck-hospitality",
    "X-App-Type": "hospitality",
    "Accept-Encoding": "gzip, deflate, br",
  },
})

export const httpViaCep = axios.create({
  baseURL: "https://viacep.com.br/",
})

http.interceptors.request.use((req) => {
  req.headers["lang"] = LangValues[store.state.layout.currentLang]

  return req
})

/**
 * Adiciona headers de autenticação e outros
 * padrões nas requisições
 */
http.interceptors.request.use((req) => {
  if (req.url.includes("amazonaws.com")) {
    return req
  }

  const authToken = store.state.guest.auth.token
  const groupOrCompany = store.state.company.groupOrCompany

  if (groupOrCompany) {
    req.headers["X-Group-or-Company"] = groupOrCompany
  }

  if (authToken) {
    req.headers["Authorization"] = `Bearer ${authToken}`
  }

  return req
})

export default http
