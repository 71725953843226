import Vue from "vue"
import Vuex from "vuex"

import router from "@/router"

// Informações de negócio
import company from "./company"
// Fluxo da aplicação configurados
// com base nos dados de negócio
import flow from "./flow"
import guest from "./guest"
// Informações de aplicação
import layout from "./layout"
// Snackbar para mensagens
import notifications from "./notifications"
import parameters from "./parameters"
import reservation from "./reservation"
import { actions } from "./root"
import search from "./search"
import warranty from "./warranty"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions,
  modules: {
    warranty,
    company,
    search,
    reservation,
    guest,
    parameters,
    layout,
    notifications: notifications(),
    flow: flow(router),
  },
})

export default store
export const useStore = () => store
