<template>
  <div style="display: inline">
    <transition v-if="$vuetify.breakpoint.smAndDown" name="fade">
      <div v-if="isOverlayOpened" class="overlay"></div>
    </transition>
    <z-chip
      ref="chip"
      data-cy="my-reservation"
      small
      :text="$t('reservation.my_reservation')"
      @click="onClickOpen"
    />
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-menu
        bottom
        left
        offset-y
        :nudge-bottom="10"
        :nudge-right="70"
        :value="isVisible"
        :activator="$refs.chip"
        :close-on-content-click="false"
      >
        <my-reservation-card :reservation="reservation" :hotel="hotel" />
      </v-menu>
    </template>
    <div v-else class="my-reservation-container">
      <transition name="slide">
        <my-reservation-card
          v-if="isVisible"
          class="my-reservation-card"
          :reservation="reservation"
          :hotel="hotel"
          @close="onClose"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from "vue"

import MyReservationCard from "./_MyReservationCard.vue"

export default {
  components: { MyReservationCard },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
    hotel: {
      type: Object,
      required: true,
    },
  },
  setup(_, { emit }) {
    const isVisible = ref(false)
    const isOverlayOpened = ref(false)

    const onClickOpen = () => {
      emit("click")
      isVisible.value = true
      setTimeout(() => {
        isOverlayOpened.value = true
      }, 100)
    }

    const onClose = () => {
      if (isOverlayOpened.value) {
        isOverlayOpened.value = false
        isVisible.value = false
      }
    }

    return {
      isVisible,
      isOverlayOpened,
      onClose,
      onClickOpen,
    }
  },
}
</script>

<style lang="scss">
#reservation-map {
  .leaflet-control-attribution {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.my-reservation-container {
  border-radius: 30px 30px 0 0 !important;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  width: inherit;
  padding: 0;
  z-index: 3;

  .my-reservation-card {
    border-radius: 30px 30px 0 0 !important;
    width: inherit;
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.fade-enter-active {
  transition: opacity 0.2s ease-in;
}

.fade-leave-active {
  transition: none;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

/* CARD TRANSITION */
.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}

.slide-enter-to,
.slide-leave {
  transform: translateY(0);
}

.slide-enter-active {
  transition: all 0.5s ease-out;
}

.slide-leave-active {
  transition: all 0.3s ease-in;
}
</style>

<style>
.no-scroll {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  /* Other browsers */
  overscroll-behavior: none;
}
</style>
