<template>
  <div
    class="welcome-layout"
    :style="{
      background: `url(${
        theme.backgroundUrl || require('@/assets/images/welcome-background.svg')
      })`,
    }"
  >
    <img
      :src="theme.logo.external || require('@/assets/images/logo-hotel.svg')"
      alt="Logo Hotel"
      class="logo"
    />

    <div v-if="$vuetify.breakpoint.mdAndUp" class="language-select-desktop">
      <language-select />
    </div>
    <z-page-transition type="fade">
      <router-view></router-view>
    </z-page-transition>
    <img
      class="powered-by"
      src="@/assets/images/poweredby.svg"
      alt="Powered by Zoox"
    />
    <notification />
  </div>
</template>

<script>
import { useNamespacedState } from "vuex-composition-helpers/dist"

export default {
  setup() {
    const { theme } = useNamespacedState("layout", ["theme"])

    return { theme }
  },
}
</script>

<style lang="scss" scoped>
.welcome-layout {
  display: grid;
  gap: 20px;
  justify-items: center;
  align-items: start;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 20px;
}

.logo {
  max-height: 120px;
  max-width: 100%;
}

.powered-by {
  align-self: end;
  justify-self: end;
}

.language-select-desktop {
  position: absolute;
  right: 20px;
  top: 10px;
  background-color: #fff;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .powered-by {
    justify-self: center;
  }
}
</style>
