import "@/assets/scss/global.scss"

import Vue from "vue"
import VueGtag from "vue-gtag"
import VueSignaturePad from "vue-signature-pad"

import App from "./App.vue"
import LocalComponents from "./components"
import i18n from "./i18n"
import { vuetify } from "./plugins/designSystem"
import router from "./router"
import store from "./store"

Vue.config.productionTip = false

if (process.env.NODE_ENV !== "production") {
  Vue.config.debug = process.env.VUE_APP_DEBUG
}

async function main() {
  if (
    !document.location.hostname.startsWith("localhost") &&
    process.env.NODE_ENV === "production" &&
    process.env.VUE_APP_GTAG
  ) {
    Vue.use(VueGtag, {
      config: { id: process.env.VUE_APP_GTAG },
    })
  }

  Vue.use(LocalComponents)
  Vue.use(VueSignaturePad)

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount("#app")
}

main()
