<template>
  <z-card
    data-cy="guest-card"
    class="smck-guest-card"
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '420px'"
    :shadow="$vuetify.breakpoint.mdAndUp"
    @click="onClickCard"
  >
    <template #title>
      <div class="smck-guest-card-header">
        <z-chip
          v-if="isMainGuest"
          ref="mainGuest"
          selected
          x-small
          :text="$t('reservation.chip_main_guest')"
          :color="$zoox.colors.black300"
        />
        <z-chip v-else ref="nonMainGuest" x-small text="Acompanhante" />
        <z-chip
          v-if="!completed && wasNotified"
          ref="waiting"
          x-small
          selected
          :text="$t('reservation.chip_waiting')"
          :color="$zoox.colors.orange"
        />
        <z-chip
          v-if="completed"
          ref="completed"
          x-small
          selected
          :text="$t('reservation.chip_completed')"
          :color="$zoox.colors.lightgreen"
        />
      </div>
    </template>
    <template #content>
      <div class="smck-guest-card-content">
        <z-avatar class="smck-guest-card-avatar" size="64px" :photo="avatar" />
        <div class="card-title">{{ name }}</div>
        <div class="body3-bold">{{ contact || $t("guest.no_email") }}</div>
      </div>

      <z-btn
        v-if="
          $vuetify.breakpoint.smAndDown &&
          !completed &&
          guestCardSelected === index
        "
        data-cy="guest-card-fill"
        primary
        :is-loading="isLoading"
        :text="$t('reservation.fill_guest_record')"
        @click="$emit('click:guest-card-fill')"
      />
      <z-btn
        v-if="
          $vuetify.breakpoint.smAndDown &&
          completed &&
          guestCardSelected === index
        "
        primary
        :text="$t('guest.edit_data')"
        :is-loading="isLoading"
        @click="$emit('click:guest-card-edit')"
      />
    </template>
    <template v-if="$vuetify.breakpoint.mdAndUp" #actions>
      <z-tooltip v-if="!isLoading">
        <template #activator="{ on, attrs }">
          <z-icon
            data-cy="edit-btn"
            v-bind="attrs"
            v-on="on"
            @click="$emit('click:guest-card-edit')"
          >
            $edit_rounded
          </z-icon>
        </template>

        <p class="body-text">{{ $t("guest.edit_information") }}</p>
      </z-tooltip>

      <z-circular-progress
        v-else
        color="var(--primary)"
        value="10"
        size="20"
        width="5"
        indeterminate
        rotate
      />

      <z-icon
        v-if="renderSendNotificationButton && !completed"
        data-cy="send-notification"
        class="ml-2"
        @click.stop="$emit('click:guest-card-notification')"
      >
        $share_rounded
      </z-icon>
    </template>
  </z-card>
</template>

<script>
export default {
  props: {
    avatar: {
      type: String,
      default: require("@/assets/images/profile-avatar.svg"),
    },
    name: {
      type: String,
      default: "",
    },
    contact: {
      type: String,
      default: "",
    },
    isMainGuest: {
      type: Boolean,
      default: false,
    },
    wasNotified: {
      type: Boolean,
      default: false,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    guestCardSelected: {
      type: Number,
      default: 999,
    },
    index: {
      type: Number,
      default: 0,
    },
    renderSendNotificationButton: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClickCard() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$emit("click:guest-card")
      } else {
        if (this.isLoading) return

        this.$emit("click:guest-card-edit")
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.smck-guest-card {
  display: grid;
  row-gap: 10px;
}

.smck-guest-card-header {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
}

.smck-guest-card-content {
  display: grid;
  column-gap: 15px;
  row-gap: 10px;
  align-content: center;
  grid-template-columns: auto 1fr;
}

.smck-guest-card-avatar {
  grid-row: span 2;
}

.smck-guest-card {
  padding: 10px;
}
.card-title {
  align-self: flex-end;
}
</style>
