<template>
  <div
    ref="animation"
    :style="{ width: `${width}px`, height: `${height}px` }"
  ></div>
</template>

<script>
import lottie from "lottie-web"

import loadingAnimation from "@/assets/animations/payment-loading.json"

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    once: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 200,
    },
    width: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return { animation: null }
  },

  watch: {
    isLoading(isLoading) {
      if (!isLoading) {
        this.animation.resetSegments()
        this.animation.loop = false
      } else {
        this.animation.playSegments([1, 90], true)
        this.animation.loop = true
      }
    },
  },

  mounted() {
    const animation = lottie.loadAnimation({
      container: this.$refs.animation,
      renderer: "svg",
      autoplay: true,
      loop: !this.once,
      animationData: loadingAnimation,
    })

    if (!this.once) {
      animation.playSegments([1, 90], true)
    }

    this.animation = animation
  },
}
</script>
