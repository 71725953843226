/**
 * Módulo responsável por armazenar informações
 * do fluxo, com base nos steps disponíveis pelo sistema
 * e configurações da aplicação feitas pelo ADMIN
 */
import find from "lodash.find"
import goTo from "vuetify/lib/services/goto"

import router from "@/router"
import { changeChunkDeep } from "@/utils/vuex"

import { Step, StepType, StepVisualization } from "./step"

/**
 *
 */
const state = {
  config: {
    isMainGuest: false,
  },
  currentStep: null,
  steps: [],
}

/**
 *
 */
const mutations = () => ({
  changeConfig: changeChunkDeep("config"),
  setIfMatch(state, route) {
    const step = find(state.steps, { route })

    if (step) {
      state.currentStep = step
    }
  },
  clearSteps(state) {
    state.steps = []
  },
  addStep(state, step) {
    state.steps.push(step)
  },
  setCurrentStep(state, step) {
    state.currentStep = step
    router.push({
      name: state.currentStep.route,
      query: { fromPrevious: false },
    })
  },

  setStep(state, stepRoute) {
    let currentStep = state.steps.find((step) => step.route === stepRoute)

    state.currentStep = currentStep
  },

  previous(state) {
    const index = state.steps.indexOf(state.currentStep)

    if (index > 0) {
      state.currentStep = state.steps[index - 1]
      router.push({
        name: state.currentStep.route,
        query: { fromPrevious: true },
      })
    }
  },
  next(state) {
    const index = state.steps.indexOf(state.currentStep)

    if (index < state.steps.length - 1) {
      state.currentStep = state.steps[index + 1]
      router.push({
        name: state.currentStep.route,
        query: { fromPrevious: false },
      })
    }
  },
})

/**
 *
 */
const actions = {
  /**
   * Carrega os steps disponíveis com base na configuração
   * da company e tipo de guest para o formulário de preenchimento
   */
  async start({ commit, state, getters, rootGetters, rootState }, config) {
    commit("clearSteps")
    commit("changeConfig", config)

    commit("addStep", Step("personal-info", "guest.personal_info"))

    if (getters.hasAdditionalData) {
      commit("addStep", Step("additional-data", "guest.additional_data"))
    }

    commit("addStep", Step("confirmation", "guest.data_confirmation"))

    if (rootGetters["company/requiredSignature"]) {
      commit(
        "addStep",
        Step(
          "signature",
          "Signature",
          {},
          StepType.Extra,
          StepVisualization.AdultOnly
        )
      )
    }

    if (state.config.isMainGuest && rootState.guest.hasOrder) {
      commit(
        "addStep",
        Step("warranty", "warrantyRegister.warranty_registration")
      )
    }

    commit("setCurrentStep", state.steps[0])
  },

  peek({ commit }, stepId) {
    commit("peek", stepId)
  },

  previous({ commit }) {
    commit("previous")
  },

  async next({ commit, dispatch, getters }) {
    goTo(0)

    if (getters.isLastStep) {
      await dispatch("guest/saveGuest", {}, { root: true })
      await dispatch("guest/saveFNRH", {}, { root: true })
      await dispatch("reservation/reloadReservation", {}, { root: true })
      router.push({ name: "home" })
      return
    }

    commit("next")
    dispatch("guest/saveGuest", {}, { root: true })
  },
}

/**
 *
 */
const getters = {
  isLastStep(state) {
    const currentStepIndex = state.steps.findIndex(
      (step) => step === state.currentStep
    )

    return currentStepIndex === state.steps.length - 1
  },
  hasAdditionalData(_, __, ___, rootGetters) {
    const travelDataConfig =
      rootGetters["company/additionalDataConfig"].travelData
    const additionalDataConfig =
      rootGetters["company/additionalDataConfig"].additionalData

    return (
      !travelDataConfig.arrivingFromCountry.default ||
      !travelDataConfig.arrivingFromState.default ||
      !travelDataConfig.arrivingFromCity.default ||
      !travelDataConfig.nextDestinationCountry.default ||
      !travelDataConfig.nextDestinationState.default ||
      !travelDataConfig.nextDestinationCity.default ||
      !travelDataConfig.purposeTrip.default ||
      !travelDataConfig.transportType.default ||
      Object.keys(additionalDataConfig).length > 0
    )
  },
}

/**
 *
 */
export default (router) => ({
  namespaced: true,
  state,
  actions,
  getters,
  mutations: mutations(router),
})
