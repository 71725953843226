export const formatMobile = (mobile) => {
  const splited = mobile.split(" ")

  let [ddi, ddd, phoneNumber] = splited

  ddi = ddi && ddi.replace("+", "")
  ddd = ddd && ddd.replace("(", "")
  ddd = ddd && ddd.replace(")", "")
  phoneNumber = phoneNumber.replace("-", "")

  return `${ddi}${ddd}${phoneNumber}`
}

export const formatDefaultMobile = (mobile) => {
  const sanitizedMobile = mobile.substring(2).trim()
  const ddd = mobile.slice(0, 2)

  let prefix = sanitizedMobile

  if (sanitizedMobile.length === 8) {
    prefix = sanitizedMobile.slice(0, 4)
  }

  if (sanitizedMobile.length === 9) {
    prefix = sanitizedMobile.slice(0, 5)
  }

  const suffix = sanitizedMobile.slice(-4)
  return `+55 (${ddd}) ${prefix}-${suffix}`
}
