<template>
  <div
    style="position: absolute; top: 0; height: 100vh; left: 0; bottom: 0"
    class="mobile-layout"
  >
    <div
      :opened="opened"
      class="pass-hospitality-mobile document-scanner-mobile"
    >
      <span
        class="close-btn-mobile align-self-end mr-3 mt-3"
        @click="$emit('close')"
      >
        <z-icon size="20px" color="white"> $close_rounded </z-icon>
      </span>
      <img
        v-if="true"
        class="shade-image-mobile"
        src="@/assets/images/shade-document-scanner.svg"
      />
      <img
        v-else
        class="shade-image-mobile"
        src="@/assets/images/Subtract-certidao.svg"
      />
      <z-tabs
        ref="scannerTabs"
        class="document-scanner-tabs"
        android
        grow
        dark
        color="white"
        :tabs="tabOptions"
        :value="currentTab"
        @change="currentTab = $event"
      />
      <div
        v-if="isCropping"
        ref="test"
        class="document-scan-instructions-crop-mobile body3"
      >
        {{ $t("documentScanner.scanner_mobile_instruction") }}
      </div>
      <document-camera ref="cameraStream" />
      <z-photo-upload
        v-if="isCropping"
        ref="photoUpload"
        style="z-index: 3"
        crop-whole-image
        dashed
        with-points
        :image="currentImage"
      />
      <img
        v-if="!isCropping"
        class="document-target-mobile"
        src="@/assets/images/mira-document-scanner-mobile.svg"
        alt="Document target"
      />
      <img
        v-else
        class="target-document"
        :style="isCropping ? 'visibility : hidden' : ''"
        src="@/assets/images/mira-certidao.svg"
        alt="Facial target"
      />
      <div
        v-if="!isCropping"
        ref="test"
        class="document-scan-instructions-mobile body3"
      >
        {{ $t("documentScanner.scanner_mobile_instruction2") }}
      </div>
      <div v-else ref="test" class="document-scan-instructions-mobile body3">
        {{ $t("documentScanner.scanner_mobile_instruction3") }}
      </div>
      <z-btn
        v-if="!isCropping"
        primary
        class="document-scan-btn-mobile"
        data-cy="button-scan-document-mobile"
        :text="$t('documentScanner.scanner_mobile_button_scan')"
        :is-loading="isLoading"
        @click="onScanClickMobile"
      />
      <z-btn
        v-else
        primary
        class="document-scan-btn-mobile"
        data-cy="button-confirm-document-mobile"
        :text="$t('documentScanner.scanner_mobile_button_confirm')"
        @click="onClickCropImage"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from "vue"

import { DocumentSide } from "@/consts"

export default {
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    front: {
      type: [String, Object],
      default: "",
    },
    frontLabel: {
      type: String,
      required: true,
    },
    back: {
      type: [String, Object],
      default: "",
    },
    backLabel: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    stream: {
      type: MediaStream,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { front, back } = toRefs(props)

    const cameraStream = ref(null)
    const currentImage = ref(null)
    const photoUpload = ref(null)
    const isCropping = ref(false)
    const scanSide = ref(DocumentSide.Front)

    const currentTab = computed(() => {
      return front.value ? DocumentSide.Back : DocumentSide.Front
    })

    const tabOptions = computed(() => {
      return [
        {
          id: "front",
          text: props.frontLabel,
          icon: front.value ? "$check_in_a_circle" : "",
          iconColor: "green",
        },
        {
          id: "back",
          text: props.backLabel,
          icon: back.value ? "$check_in_a_circle" : "",
          iconColor: "green",
        },
      ]
    })

    const onScanClickMobile = async () => {
      const image = await cameraStream.value.getPhoto()

      currentImage.value = image
      isCropping.value = true
    }

    const onClickCropImage = async () => {
      const croppedImage = photoUpload.value.getCroppedImage()
      emit("change", { image: croppedImage, side: scanSide.value })

      if (scanSide.value === DocumentSide.Back) {
        emit("finish")
      }
      if (scanSide.value === DocumentSide.Front) {
        scanSide.value = DocumentSide.Back
        isCropping.value = false
      }
    }

    return {
      isCropping,
      cameraStream,
      currentImage,
      scanSide,
      photoUpload,

      tabOptions,
      currentTab,

      onClickCropImage,
      onScanClickMobile,
    }
  },
}
</script>

<style lang="scss" scoped>
.mobile-layout {
  display: grid;
  gap: 20px;
  justify-items: center;
  align-items: start;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100vw;
  overflow-y: auto;
  padding: 20px;
}

.pass-hospitality-mobile {
  &.document-scanner-mobile {
    display: grid;
    place-items: center;
    place-content: center;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--black);
    padding: 20px;

    .target-document {
      position: absolute;
      max-height: 80vh;
      left: 0;
      right: 0;
      z-index: 2;
      margin: 0 auto;
      width: 95%;
      margin-top: 15px;
    }

    .close-btn-mobile {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      padding-right: 2px;
    }

    .shade-image-mobile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      background-position: center; /* Center the image */
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      z-index: 1;
    }
    .document-scanner-tabs {
      position: absolute;
      top: 15%;
      width: 100%;
      z-index: 2;
    }

    .certificate-birth {
      top: 8%;
    }

    .document-scan-btn-mobile {
      width: 80%;
      justify-self: center;
      bottom: 5%;
      position: absolute;
      z-index: 2;
    }

    .document-scan-instructions-crop-mobile {
      color: var(--white);
      max-width: 40ch;
      text-align: center;
      margin-top: 24vh;
      position: absolute;
      top: 0;
    }
    .document-scan-instructions-mobile {
      color: var(--white);
      max-width: 40ch;
      text-align: center;
      margin-bottom: 25%;
      position: absolute;
      bottom: 0;
      margin-top: 10px;
      z-index: 2;
    }
    .document-target-mobile {
      width: 100% !important;
      z-index: 2;
      padding-top: 6px;
    }

    .zoox-photo-upload {
      div {
        .cropper-container {
          height: 350px !important;
        }
      }
    }

    @media screen and (min-width: 390px) {
      .document-target-mobile {
        width: 105% !important;
        z-index: 2;
        padding-top: 10px;
      }
    }

    @media screen and (max-width: 360px) {
      .document-target-mobile {
        width: 105% !important;
        z-index: 2;
        padding-top: 10px;
      }
    }

    @media screen and (min-width: 412px) {
      .document-target-mobile {
        width: 110% !important;
        z-index: 2;
        padding-top: 12px;
      }
    }
  }

  @media screen and (max-height: 667px) {
    .document-scan-instructions-mobile {
      display: none;
    }
    .document-scan-btn-mobile {
      bottom: 4% !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .document-target-mobile {
      visibility: hidden !important;
    }
  }
}
</style>

<style lang="scss">
.camera-stream-video {
  height: 340px !important;
  .error-text {
    visibility: hidden !important;
  }
}
.pass-hospitality-mobile {
  &.document-scanner-mobile {
    video {
      height: 100vh;
      width: 100vw;
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }
}
</style>
