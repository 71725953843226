import { startAuthentication, startRegistration } from "@simplewebauthn/browser"
import find from "lodash.find"

import http from "@/http"
import { getTokenFromStorage, saveTokenToStorage } from "@/storage/token"
import { changeChunkDeep } from "@/utils/vuex"

/**
 *
 */
const state = {
  clientId: "",
  isQualified: false,
  contacts: [],
  token: "",
  auth: {
    pin: "",
    validationMethod: "",
    validationMethodId: "",
  },
}

/**
 *
 */
const mutations = {
  changeAuth: changeChunkDeep("auth"),
  resetAuth(state) {
    state.clientId = ""
    state.isQualified = false
    state.auth.pin = ""
    state.token = ""
    state.auth.validationMethod = ""
    state.auth.validationMethodId = ""
    state.contacts = []
  },
  setQualified(state, isQualified) {
    state.isQualified = isQualified
  },
  setHasWebauthn(state, hasWebauthn) {
    state.hasWebauthn = hasWebauthn
  },
  setContacts(state, contacts) {
    state.contacts = contacts
  },
  setToken(state, token) {
    state.token = token
  },
  setClientId(state, clientId) {
    state.clientId = clientId
  },
}

/**
 *
 */
const actions = {
  async authenticateWebAuthn({ state, commit, dispatch }, guest) {
    try {
      const { data: options } = await http.post("/webauthn/authenticate", {
        personId: guest.personId,
      })

      if (options) {
        commit("setHasWebauthn", true)
      }

      const authResp = await startAuthentication(options)

      const resp = await http.post("/webauthn/authenticate-response", {
        personId: state.contacts.personId,
        ...authResp,
      })

      commit("setToken", resp.data.token)
      saveTokenToStorage(guest.id, resp.data.token)
      dispatch(
        "flow/guest/start",
        { isMainGuest: state.guest.masterGuest, isChild: false },
        { root: true }
      )
    } catch (e) {
      commit("setHasWebauthn", false)
      throw new Error(e)
    }
  },

  /**
   *
   */
  async registerWebAuthn({ state }) {
    try {
      const { data: options } = await http.post("/webauthn/register", {
        personId: state.contacts.personId,
      })

      await actions.registerResponseWebauthn(options)
    } catch (e) {
      throw new Error(e)
    }
  },

  /**
   *
   */
  async registerResponseWebauthn(opts) {
    try {
      const authResp = await startRegistration(opts)
      const { data: responseData } = await http.post(
        "/webauthn/register-response",
        {
          personId: state.auth.contactInfos.personId,
          ...authResp,
        }
      )

      return responseData
    } catch (e) {
      throw new Error("Erro ao registrar biometria")
    }
  },

  /**
   *
   */
  async tryAuthenticate({ commit, dispatch }, guest) {
    commit("resetAuth")
    const { isValid, token } = getTokenFromStorage(guest.id)

    if (isValid) {
      commit("setQualified", true)
      commit("setToken", token)
      return
    }

    await dispatch("getContactsToAuthenticate", guest)
  },

  /**
   *
   */
  async getContactsToAuthenticate({ commit }, guest) {
    try {
      commit("setContacts", [])
      const resp = await http.get(`/clients/${guest.id}/contact-info`)

      commit("setContacts", resp.data.contactInfoList)
      commit("setQualified", true)
    } catch (e) {
      if (e.response?.status === 404) {
        commit("setQualified", false)
      }
      throw e
    }
  },

  /**
   *
   */
  async getAuthData({ commit }, guest) {
    commit("resetAuth")
    const resp = await http.get(`/clients/${guest.id}/contact-info`)

    commit("setContacts", resp.data.contactInfoList)
  },

  /**
   * Envia o PIN para o email ou celular o Person
   */
  async sendPin({ state, rootState }, guest) {
    const contact = find(state.contacts, {
      value: state.auth.validationMethod,
    })
    const contactInfo = JSON.stringify({
      guestId: guest.id,
      contactId: contact.id,
    })

    localStorage.setItem("contactInfo", contactInfo)
    await http.post(`/clients/${guest.id}/pin`, {
      companyId: rootState.company.companyId,
      id: guest.id,
      contactId: contact.id,
    })
  },

  /**
   * Envia o PIN para autenticar o usuário e salva o token
   */
  async verifyPin({ state, commit }, guest) {
    const contact = find(state.contacts, {
      value: state.auth.validationMethod,
    })

    const resp = await http.post(
      `/clients/${guest.id}/pin/${contact.id}/verify`,
      { verificationCode: state.auth.pin }
    )

    commit("setToken", resp.data.token)
    saveTokenToStorage(guest.id, resp.data.token)
  },

  /**
   * Entra diretamente na reserva verificando o PIN pelo hash do contato
   */
  async verifyPinByHash({ commit }, { contactId, guestId, hash }) {
    const resp = await http.post(
      `/clients/${guestId}/pin/${contactId}/verify`,
      { verificationCode: hash }
    )

    const hardenedGuestId = guestId ?? resp.data.clientId

    commit("setClientId", hardenedGuestId)
    commit("setToken", resp.data.token)
    saveTokenToStorage(hardenedGuestId, resp.data.token)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
