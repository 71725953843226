<template>
  <div class="main-body">
    <main-header
      :reservation="reservation"
      :hotel="hotel"
      :logo="theme.logo.internal || undefined"
      :show-info-icon="!!getAboutMessage()"
      @click:my-reservation="trackEvent('click_my_reservation')"
      @click:info="handleAboutPageModal"
    />

    <z-page-transition
      :type="$vuetify.breakpoint.smAndDown ? 'fade' : 'fade-in-right'"
    >
      <router-view></router-view>
    </z-page-transition>
    <about-page-modal
      :body-text="getAboutMessage()"
      data-cy="about-page-modal"
      :opened="isAboutPageModalOpen"
      @click:close="handleAboutPageModal"
    />
    <notification />
  </div>
</template>

<script setup>
import { ref } from "vue"
import { useRouter } from "vue-router/composables"
import { useNamespacedState } from "vuex-composition-helpers/dist"

import { useTracking } from "@/composables"
import i18n from "@/i18n"

import AboutPageModal from "./modals/AboutPageModal.vue"

const { theme } = useNamespacedState("layout", ["theme"])
const { hotel } = useNamespacedState("company", ["hotel"])
const { reservation } = useNamespacedState("reservation", ["reservation"])
const { trackEvent } = useTracking()

const router = useRouter()

const isAboutPageModalOpen = ref(false)
const handleAboutPageModal = () => {
  isAboutPageModalOpen.value = !isAboutPageModalOpen.value
  if (isAboutPageModalOpen.value) {
    trackEvent("click_about_page", { route: router.route.name })
  }
}

const getAboutMessage = () => {
  const currentRouter = router.history.current.name

  switch (currentRouter) {
    case "home":
      return i18n.tc("modal.about_guest_list")

    case "notification":
      return i18n.tc("modal.about_guest_notification")

    case "personal-info":
    case "additional-data":
      return i18n.tc("modal.about_FNRH")

    case "confirmation":
      return i18n.tc("modal.about_confirmation")

    case "signature":
      return i18n.tc("modal.about_signature")

    case "accommodation":
      return i18n.tc("modal.about_accommodation_preferences")

    default:
      break
  }
}
</script>

<style lang="scss" scoped>
.main-body {
  background-color: var(--background) !important;
  padding: 10px;
  height: 100%;
}
</style>
