<template>
  <div>
    <signature-pad-modal
      :opened="opened"
      :is-loading="isLoading"
      @back="$store.dispatch('flow/guest/previous')"
      @change="onChange"
    />
    <finalized-fnrh-modal
      v-model="isFinalizedFnrhModalOpen"
      :is-loading="isLoadingNext"
      @click:ok="onClickOk"
    />
  </div>
</template>

<script>
import { onMounted, ref } from "vue"
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useStoreAction } from "@/composables"
import { useTracking } from "@/composables/useTracking"
import i18n from "@/i18n"

import FinalizedFnrhModal from "../components/FinalizedFnrhModal.vue"
import SignaturePadModal from "../components/SignaturePadModal.vue"

export default {
  components: { FinalizedFnrhModal, SignaturePadModal },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isFinalizedFnrhModalOpen = ref(false)
    const { trackEvent } = useTracking()
    const { changeSignature } = useNamespacedMutations("guest", [
      "changeSignature",
    ])
    const { hasOrder } = useNamespacedState("guest", ["hasOrder"])

    const { sendSignature, isLoading } = useStoreAction(
      "guest",
      "sendSignature"
    )
    const { sendNotification } = useNamespacedActions("notifications", [
      "sendNotification",
    ])

    const { next, isLoading: isLoadingNext } = useStoreAction(
      "flow/guest",
      "next"
    )

    const onChange = async (value) => {
      changeSignature(value)
      await sendSignature()

      if (hasOrder.value) {
        await next()
      } else {
        isFinalizedFnrhModalOpen.value = true
      }
    }

    const onClickOk = async () => {
      try {
        await next()
        isFinalizedFnrhModalOpen.value = false
      } catch (e) {
        sendNotification({
          message: i18n.tc("error.generic_error"),
          duration: 3000,
          color: "var(--red-500)",
        })
      }
    }

    onMounted(() => trackEvent("step_signature"))

    return {
      isFinalizedFnrhModalOpen,
      isLoading,
      isLoadingNext,
      onClickOk,
      onChange,
    }
  },
}
</script>
