import { computed, ref } from "vue"

export function useCountdownTimer(seconds = 90) {
  const waitingTimer = ref(seconds)
  const isTimerOn = ref(false)

  const onWaitingTimer = () => {
    waitingTimer.value = seconds

    isTimerOn.value = true

    const interval = setInterval(() => {
      if (waitingTimer.value > 0) {
        waitingTimer.value--
      } else {
        isTimerOn.value = false
        clearInterval(interval)
      }
    }, 1000)
  }

  const waitingMinutes = computed(() => {
    let minutes = Math.floor(waitingTimer.value / 60)
    let seconds = waitingTimer.value % 60

    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`
  })

  const resetWaitingTimer = () => {
    isTimerOn.value = false
    waitingTimer.value = 0
  }

  return {
    waitingMinutes,
    isTimerOn,
    onWaitingTimer,
    resetWaitingTimer,
  }
}
