<template>
  <z-card-modal
    data-cy="has-children-modal"
    closable
    :title="$t('alerts.has_children_title')"
    :opened="isOpened"
    @change="$emit('close')"
  >
    <template #content>
      <div class="has-children-content">
        <div class="body-text">
          {{ $t("alerts.has_children_description") }}:
        </div>
        <z-card-accordion
          v-for="(item, index) of items"
          :key="index"
          v-model="itemsControl[index]"
          :title="item.title"
          :color="colors[index % items.length]"
        >
          <template #content>
            {{ item.content }}
          </template>
        </z-card-accordion>
        <div class="body-text">
          {{ $t("alerts.has_children_complements[0]") }}
        </div>

        <div class="body-text">
          {{ $t("alerts.has_children_complements[1]") }}
        </div>
        <z-btn
          data-cy="understand-btn"
          class="understand-btn"
          primary
          :text="$t('alerts.i_understand')"
          @click="$emit('close')"
        />
      </div>
    </template>
  </z-card-modal>
</template>

<script>
import { reactive } from "vue"

import i18n from "@/i18n"

const COLORS = [
  "orange",
  "red",
  "darkgreen",
  "primary",
  "yellow",
  "purple",
  "darkblue",
  "lightgreen",
]

export default {
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const items = [
      {
        title: i18n.tc("alerts.has_children_topics[0]"),
        content: i18n.tc("alerts.has_children_contents[0]"),
      },
      {
        title: i18n.tc("alerts.has_children_topics[1]"),
        content: i18n.tc("alerts.has_children_contents[1]"),
      },
      {
        title: i18n.tc("alerts.has_children_topics[2]"),
        content: i18n.tc("alerts.has_children_contents[2]"),
      },
    ]

    const objItemsControl = {}
    for (let i = 0; i < items.length; ++i) {
      objItemsControl[i] = false
    }
    const itemsControl = reactive(objItemsControl)

    return {
      colors: COLORS,
      items,
      itemsControl,
    }
  },
}
</script>

<style lang="scss" scoped>
.has-children-content {
  display: grid;
  row-gap: 15px;
  margin-top: 20px;
}

@media screen and (min-width: 786px) {
  .understand-btn {
    width: 150px;
    justify-self: end;
  }
}
</style>
