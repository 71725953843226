<template>
  <div>
    <div v-if="$vuetify.breakpoint.smAndDown" class="guest-desktop">
      <z-avatar
        :photo="photo || require('@/assets/images/profile-avatar.svg')"
        :size="size"
        name="Photo"
      />
      <z-btn
        class="guest-avatar-btn"
        icon="$camera_alt_rounded"
        primary
        @click="$emit('click:upload')"
      />
    </div>
    <div v-else class="guest-mobile">
      <z-avatar
        :photo="photo || require('@/assets/images/profile-avatar.svg')"
        :size="size"
        name="Photo"
      />
      <a
        v-if="!hideEdit"
        class="text-uppercase body2 mt-4"
        @click="$emit('click:upload')"
      >
        Editar foto
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    photo: {
      type: String,
      default: require("@/assets/images/profile-avatar.svg"),
    },
    size: {
      type: String,
      default: "200",
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.guest-desktop {
  position: relative;
  height: 225px;
  width: 200px;
}
.guest-avatar-btn {
  border-radius: 50% !important;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);

  :deep(i) {
    margin: 0 !important ;
  }
}
.guest-mobile {
  position: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  width: 200px;
}
</style>
