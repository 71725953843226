import payment from "./warranty/payment"

const state = {}

export default {
  namespaced: true,
  state,
  modules: {
    payment,
  },
}
