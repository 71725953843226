import { DocumentMasks, DocumentTypeValues } from "@/consts"
import i18n from "@/i18n"

/**
 * Retorna uma lista de documentos com base nos que estão ativos ou
 * não pelo admin
 */
export function getDocumentTypesList(types = {}) {
  const documents = []

  if (types.id)
    documents.push({
      text: i18n.tc("parameters.id"),
      value: DocumentTypeValues.ID,
    })
  if (types.dni)
    documents.push({
      text: i18n.tc("parameters.dni"),
      value: DocumentTypeValues.DNI,
    })
  if (types.cie)
    documents.push({
      text: i18n.tc("parameters.cie"),
      value: DocumentTypeValues.CIE,
    })
  if (types.rut)
    documents.push({
      text: i18n.tc("parameters.rut"),
      value: DocumentTypeValues.RUT,
    })

  if (types.cpf)
    documents.push({
      text: i18n.tc("parameters.cpf"),
      mask: DocumentMasks.CPF,
      value: DocumentTypeValues.CPF,
    })

  if (types.passport)
    documents.push({
      text: i18n.tc("parameters.passport"),
      value: DocumentTypeValues.PASSPORT,
    })

  if (types.birthCertificate)
    documents.push({
      text: i18n.tc("parameters.birth_certificate"),
      value: DocumentTypeValues.BIRTH_CERTIFICATE,
    })

  return documents
}
