/**
 * Módulo responsável por armazenar informações
 * do fluxo, com base nos steps disponíveis pelo sistema
 * e configurações da aplicação feitas pelo ADMIN
 */
import find from "lodash.find"

import router from "@/router"
import { changeChunkDeep } from "@/utils/vuex"

import { Step } from "./step"

/**
 *
 */
const state = {
  config: {
    hasWelcomePage: false,
    hasReservationId: false,
  },
  currentStep: null,
  steps: [],
}

/**
 *
 */
const mutations = () => ({
  changeConfig: changeChunkDeep("config"),
  setIfMatch(state, route) {
    const step = find(state.steps, { route })

    if (step) {
      state.currentStep = step
    }
  },
  clearSteps(state) {
    state.steps = []
  },
  addStep(state, step) {
    state.steps.push(step)
  },
  addStepAsNext(state, step) {
    const currentIndex = state.steps.indexOf(state.currentStep)

    state.steps.splice(currentIndex + 1, 0, step)
  },
  setCurrentStep(state, step) {
    state.currentStep = step
  },
  goToStepRoute(state) {
    if (router.currentRoute.name !== state.currentStep.route) {
      router.push({
        name: state.currentStep.route,
      })
    }
  },
  previous(state) {
    const index = state.steps.indexOf(state.currentStep)

    if (index > 0) {
      state.currentStep = state.steps[index - 1]
      router.push({
        name: state.currentStep.route,
        query: { fromPrevious: true },
      })
    }
  },
  next(state) {
    const index = state.steps.indexOf(state.currentStep)

    if (index < state.steps.length - 1) {
      state.currentStep = state.steps[index + 1]
      router.push({
        name: state.currentStep.route,
        query: { fromPrevious: false },
      })
    }
  },
  removeStepById(state, stepId) {
    state.steps = state.steps.filter((step) => step.id !== stepId)
  },
})

/**
 *
 */
const actions = {
  /**
   * Carrega os steps disponíveis com base na configuração
   * da company e tipo de guest para o formulário de preenchimento
   */
  start({ commit, state }, config) {
    commit("clearSteps")
    commit("changeConfig", config)
    if (state.config.hasWelcomePage) {
      commit("addStep", Step("welcome", "Welcome"))
    }
    if (!state.config.hasReservationId) {
      commit("addStep", Step("welcome-menu", "Welcome Menu"))
      commit("addStep", Step("find-your-reservation", "Find Your Reservation"))
    }
    commit("addStep", Step("home", "Home Page"))
    commit("setCurrentStep", state.steps[0])
  },

  addMultiReservationStep({ commit }) {
    commit("addStepAsNext", Step("multi-reservation", "Multiple Reservation"))
  },

  removeMultiReservationStep({ commit }) {
    commit("removeStepById", "multi-reservation")
  },

  peek({ commit }, stepId) {
    commit("peek", stepId)
  },

  previous({ commit }) {
    commit("previous")
  },

  next({ commit }) {
    commit("next")
  },
}

/**
 *
 */
const getters = {}

/**
 *
 */
export default (router) => ({
  namespaced: true,
  state,
  actions,
  getters,
  mutations: mutations(router),
})
