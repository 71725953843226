import dayjs from "dayjs"

const TOKEN_KEY = "zooxpass:token"

/**
 * Pega o token do guestID selecionado
 */
export function getTokenFromStorage(guestId) {
  const tokenStorageJSON = localStorage.getItem(TOKEN_KEY + ":" + guestId)
  const tokenStorage = tokenStorageJSON ? JSON.parse(tokenStorageJSON) : {}
  const existsToken = tokenStorage?.expiration

  if (existsToken) {
    const isExpired = dayjs(tokenStorage.expiration).isBefore(
      dayjs().subtract(50, "m")
    )

    if (isExpired) {
      localStorage.removeItem(TOKEN_KEY + ":" + guestId)

      return {
        isValid: false,
        token: "",
      }
    }

    return {
      isValid: true,
      token: tokenStorage.token,
    }
  }

  return {
    isValid: false,
    token: "",
  }
}

/**
 * Salva o token do guest atual
 */
export function saveTokenToStorage(guestId, token) {
  localStorage.setItem(
    TOKEN_KEY + ":" + guestId,
    JSON.stringify({ token, expiration: dayjs().toISOString() })
  )
}
