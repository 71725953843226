import store from "@/store"

/**
 * Seta o currentStep do fluxo caso de match
 * com alguma rota do fluxo.
 * Tratamento feito caso seja clicado o voltar/next do navegador
 */
export function setFlowStep(to, _, next) {
  store.commit("flow/reservation/setIfMatch", to.name)
  store.commit("flow/guest/setIfMatch", to.name)
  next()
}
