import axios from "axios"

import store from "./store"

/**
 * Instância padrão para fazer chamadas HTTP
 */

const http = axios.create({
  baseURL: process.env.VUE_APP_PAYMENT,
  headers: {
    lang: "pt",
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-App-Name": "smck-hospitality",
    "X-App-Type": "hospitality",
  },
})

/**
 * Adiciona headers de autenticação e outros
 * padrões nas requisições
 */
http.interceptors.request.use((req) => {
  if (req.url.includes("amazonaws.com")) {
    return req
  }

  const authToken = store.state.guest.auth.token
  const groupOrCompany = store.state.company.groupOrCompany

  if (groupOrCompany) {
    req.headers["X-Group-or-Company"] = groupOrCompany
  }

  if (authToken) {
    req.headers["Authorization"] = `Bearer ${authToken}`
  }

  return req
})

export default http
